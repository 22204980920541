import { h } from 'vue';
import type {
  default as Vue,
  ComponentOptions,
} from 'vue';
import type { AsyncComponentLoader } from 'vue/types/v3-define-async-component';
import type { AsyncComponentFactory } from 'vue/types/options';


// Если загрузка компонента не удалась
const ErrorLoadingComponent: ComponentOptions<Vue> = {
  render() {
    return h(
      'div',
      {
        style: (
          'background-color: #fff1f0;' +
          'border: 1px solid #ffa39e;' +
          'padding: 12px;'
        ),
      },
      [
        h('h5', ['Error']),
        'Failed to load component',
      ],
    );
  },
};


// Иконка загрузки компонента
// Добавление анимаций
const styleElement = document.createElement('style');
styleElement.append(`
@keyframes rotate_ {
  to {
    transform: rotate(405deg);
  }
}
@keyframes spinMove_ {
  to {
    opacity: 1;
  }
}
`);
document.body.append(styleElement);

/*
  Копирование компонента "antd"
<div class="d-inline-block text-center">
  <div class="ant-spin ant-spin-spinning">
    <span class="ant-spin-dot ant-spin-dot-spin">
      <i class="ant-spin-dot-item"></i>
      <i class="ant-spin-dot-item"></i>
      <i class="ant-spin-dot-item"></i>
      <i class="ant-spin-dot-item"></i>
    </span>
  </div>
</div>
*/
const LoadingComponent: ComponentOptions<Vue> = {
  render() {
    const LoadingComponent_props4_style = (
      'animation: spinMove_ 1s linear infinite alternate;' +
      'position: absolute;' +
      'width: 7.5px;' +
      'height: 7.5px;' +
      'background-color: #1890ff;' +
      'border-radius: 100%;' +
      'opacity: .3;'
    );

    return h(
      'div',
      {
        style: (
          'display: inline-block;' +
          'text-align: center;' +
          'font-size: 14px;' +
          'width: 100%;'
        ),
      },
      [
        h(
          'div',
          { style: 'position: static;' },
          [
            h(
              'span',
              {
                style: (
                  'transform: rotate(45deg);' +
                  'animation: rotate_ 1.2s linear infinite;' +
                  'font-size: 20px;' +
                  'display: inline-block;' +
                  'width: 1em;' +
                  'height: 1em;'
                ),
              },
              [
                h('i', { style: LoadingComponent_props4_style + 'left: 0; top: 0;' }),
                h(
                  'i',
                  {
                    style: (
                      LoadingComponent_props4_style +
                      'animation-delay: .4s; right: 0; top: 0;'
                    ),
                  },
                ),
                h(
                  'i',
                  {
                    style: (
                      LoadingComponent_props4_style +
                      'animation-delay: .8s; right: 0; bottom: 0;'
                    ),
                  },
                ),
                h(
                  'i',
                  {
                    style: (
                      LoadingComponent_props4_style +
                      'animation-delay: 1.2s; left: 0; bottom: 0;'
                    ),
                  },
                ),
              ],
            ),
          ],
        ),
      ],
    );
  },
};


export function createAsyncLoadComponent(
  getComponentPromise: AsyncComponentLoader,
): AsyncComponentFactory {
  return () => ({
    component: getComponentPromise(),
    delay: 0,
    loading: LoadingComponent,
    error: ErrorLoadingComponent,
  });
}
