
import {
  defineComponent,
  // shallowReactive,
  // watch,
  computed,
  shallowRef,
  ref,
  watch,
} from 'vue';
import {
  // ConfigProvider,
  // Layout,
  Button,
  Menu,
  Icon,
  Tooltip,
  Modal,
  message,
} from 'ant-design-vue';

// TODO: перенести в "components/app"
import UploadManagerCard from '@/components/UploadManagerCard/asyncComponent';
import {
  ConfigProvider,
  Layout,
} from '@/plugins/antd/asyncLoad';
import InProgressMenuItem from '@/components/app/InProgressMenuItem.vue';
import {
  RouteName,
  router,
} from '@/router';
import { $http } from '@/plugins/axios';
import { useBillingStore } from '@/store/billing';
import { useUploadManagerStore } from '@/store/uploadManager';
import { useAuthStore } from '@/store/auth';
import { vueI18n } from '@/i18n';
import { USER_ROLE } from '@/constants';
import { useLocaleStore } from '@/store/locale';


const innerPageNames: Record<RouteName, RouteName> = {
  [RouteName.Projects]: RouteName.Projects,
  [RouteName.Samples]: RouteName.Projects,
  [RouteName.SamplesTableMode]: RouteName.Projects,
  [RouteName.SampleQuality]: RouteName.Projects,
  [RouteName.Variants]: RouteName.Projects,
  [RouteName.Variant]: RouteName.Projects,
  [RouteName.VariantIGV]: RouteName.Projects,
  [RouteName.SearchInOtherProjects]: RouteName.Projects,
  [RouteName.ExpertSamples]: RouteName.ExpertSamples,
  [RouteName.Search]: RouteName.Search,
  [RouteName.MutationsBase]: RouteName.MutationsBase,
  [RouteName.MutationsBaseSet]: RouteName.MutationsBase,
  [RouteName.Phenotypes]: RouteName.Phenotypes,
  [RouteName.FilesList]: RouteName.Files,
  [RouteName.FilesExports]: RouteName.Files,
  [RouteName.AdministrationUsers]: RouteName.Administration,
  [RouteName.AdministrationOrganizations]: RouteName.Administration,
  [RouteName.AdministrationUserOperations]: RouteName.Administration,
  [RouteName.AdministrationPanels]: RouteName.Administration,
  [RouteName.AdministrationReportTemplates]: RouteName.Administration,
  [RouteName.AdministrationPlanRates]: RouteName.Administration,
  [RouteName.AdministrationValidation]: RouteName.Administration,
  [RouteName.Billing]: RouteName.Billing,
  [RouteName.Account]: RouteName.Account,
  [RouteName.Coverage]: RouteName.Projects,
  [RouteName.CoverageSchema]: RouteName.Projects,
  [RouteName.CoverageTable]: RouteName.Projects,
  [RouteName.CoverageBarchart]: RouteName.Projects,
  [RouteName.CoverageSampleLog]: RouteName.Projects,
  // Не используется
  [RouteName.Login]: RouteName.Login,
  [RouteName.Files]: RouteName.Files,
  [RouteName.Administration]: RouteName.Administration,
} as const;


export default defineComponent({
  name: 'App',
  components: {
    'a-config-provider': ConfigProvider,
    'a-layout': Layout,
    'a-layout-header': Layout.Header,
    'a-layout-content': Layout.Content,
    'a-button': Button,
    'a-menu': Menu,
    'a-menu-item': Menu.Item,
    'a-tooltip': Tooltip,
    'a-icon': Icon,

    UploadManagerCard,
    InProgressMenuItem,
  },
  setup() {
    const billingStore = useBillingStore();
    const uploadManagerStore = useUploadManagerStore();
    const localeStore = useLocaleStore();

    const uploadManagerCardMinimized = shallowRef(true);

    const uploadManagerCardShowIconProps = computed(() => {
      return (
        uploadManagerCardMinimized.value ?
          {
            cardStyle: 'right: -290px;',
            text: vueI18n.t('common.App.uploadManagerCardMinimized'),
            click() {
              uploadManagerCardMinimized.value = false;
            },
          } :
          {
            cardStyle: 'right: 10px;',
            text: vueI18n.t('common.App.uploadManagerCardMaximized'),
            click() {
              uploadManagerCardMinimized.value = true;
            },
          }
      );
    });


    const pageKey = shallowRef(0);
    function recreatePage() {
      pageKey.value = (pageKey.value + 1) % 13;
    }

    const uploadedBytesPercentText = shallowRef<string>();
    function setUploadedBytesPercentText(value: typeof uploadedBytesPercentText.value) {
      uploadedBytesPercentText.value = value;
    }


    function toPage(pageName: RouteName) {
      router.push({ name: pageName });
    }


    const authStore = useAuthStore();
    function logOutAs() {
      authStore.logOutAs();
      router.push({ name: RouteName.AdministrationUsers });
    }


    function confirmLogOut() {
      Modal.confirm({
        ...localeStore.modalOkCancelText,
        title: vueI18n.t('common.App.confirmLogoutTitle'),
        content: (
          uploadManagerStore.isFileUploading ?
            vueI18n.t('common.App.confirmLogoutContentUnsaved') :
            vueI18n.t('common.App.confirmLogoutContentSaved')
        ),
        onOk() {
          authStore.logOut();
          uploadedBytesPercentText.value = undefined;
          uploadManagerCardMinimized.value = false;
        },
      });
    }


    const inProgressMenuItemComponent = ref<typeof InProgressMenuItem>();
    function onTaskCreated() {
      uploadManagerCardMinimized.value = true;
      inProgressMenuItemComponent.value?.getProgress();
    }


    function openUploadManagerModal() {
      uploadManagerCardMinimized.value = false;
      requestAnimationFrame(() => {
        uploadManagerStore.uploadManagerModalVisible = true;
      });
    }

    // Проверка подтверждения аккаунта
    const queryParams = new URLSearchParams(location.search);
    const email_confirmation_token = queryParams.get('email_confirmation_token');
    if (email_confirmation_token) {
      if (authStore.accessToken) {
        message.success(vueI18n.t('common.App.alreadyAuthenticated'), 3);
      } else {
        const loading = message.loading(vueI18n.t('common.App.alreadyAuthenticated'), 0);
        $http.get(
          'users/confirm-email/',
          { params: { email_confirmation_token } },
        )
          .then(() => {
            message.success(vueI18n.t('common.App.confirmEmailSuccess'), 3);
          })
          .catch((error) => {
            $http.showErrorMessage(error, vueI18n.t('common.App.confirmEmailError'));
          })
          .finally(loading);
      }
    }


    watch(
      () => localeStore.loadedLocale,
      () => {
        document.title = vueI18n.t('common.App.title');
      },
    );


    return {
      USER_ROLE,
      RouteName,

      billingStore,
      localeStore,

      // selectedPage,

      uploadManagerCardMinimized,
      uploadManagerCardShowIconProps,

      pageKey,
      recreatePage,

      uploadedBytesPercentText,
      setUploadedBytesPercentText,

      toPage,

      authStore,
      logOutAs,

      confirmLogOut,

      onTaskCreated,
      inProgressMenuItemComponent,

      openUploadManagerModal,
    };
  },
  data() {
    return { selectedPage: [router.currentRoute.name || RouteName.Login] as [RouteName] };
  },
  watch: {
    '$route'() {
      this.selectedPage[0] = (
        innerPageNames[router.currentRoute.name as RouteName] ||
        router.currentRoute.name
      ) as RouteName;
    },
  },
});
