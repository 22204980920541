import type { default as moment } from 'moment';


let _moment: typeof moment;

export function importMoment() {
  return import('moment')
    .then((module) => {
      _moment = module.default;
      return _moment;
    });
}

export function getMoment() {
  return _moment;
}
