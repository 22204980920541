import Vue from 'vue';
import VueI18n from 'vue-i18n';
import type { LocaleMessageObject } from 'vue-i18n';

import {
  importMoment,
  getMoment,
} from '@/plugins/moment/importMoment';
// // eslint-disable-next-line @typescript-eslint/ban-ts-comment
// // @ts-expect-error
// import ru_RU from 'ant-design-vue/lib/locale-provider/ru_RU';


Vue.use(VueI18n);


export enum Locale {
  ru = 'ru',
  en = 'en'
}

type AntdLocale = object;
const AntdLocalesImports: Record<Locale, () => Promise<{ default: AntdLocale }>> = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  [Locale.ru]: () => import('ant-design-vue/lib/locale-provider/ru_RU'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  [Locale.en]: () => import('ant-design-vue/lib/locale-provider/en_US'),
};

const MomentLocalesImport: Record<Locale, () => Promise<any>> = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  [Locale.ru]: () => import('moment/locale/ru'),
  [Locale.en]: () => Promise.resolve(),
};


export const defaultLocale: Locale = (
  process.env.VUE_APP_I18N_LOCALE in Locale ?
    process.env.VUE_APP_I18N_LOCALE :
    Locale.ru
);
export type LoadedLocale<L extends Locale = Locale> = [
  L,
  AntdLocale, // (typeof AntdLocalesName)[L],
  L,
];
const loadedLocales: LoadedLocale[] = [];

export const vueI18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || defaultLocale,
});


function mergeLocaleMessagesFiles(files: {
  default: LocaleMessageObject,
  name: string,
}[]) {
  const result: Record<string, LocaleMessageObject> = {};
  for (const file of files) {
    result[file.name] = file.default;
  }
  return result;
}
// TODO: возможно сделать так, чтобы функция сама получала имя файла
function addName<
  Target extends object,
  Name extends string
>(
  object: Target,
  name: Name,
): Target & { name: Name } {
  return Object.assign(object, { name });
}

function pushLoadedLocale(locale: Locale, antdLocale: AntdLocale) {
  loadedLocales.push([
    locale,
    antdLocale,
    locale,
  ]);
}
function getLoadedLocale<L extends Locale>(locale: L): LoadedLocale<L> | undefined {
  for (let index = 0; index < loadedLocales.length; index++) {
    const loadedLocale = loadedLocales[index];
    if (loadedLocale[0] === locale) {
      return loadedLocale as LoadedLocale<L>;
    }
  }
}


// Основной текст загружается сразу, а "Moment" и "Antd" чуть позже отдельно
export const loadingDefaultLocalePromise = (function() {
  // Загрузка языка "moment"
  const result = Promise.all([
    Promise.all([
      importMoment(),
      MomentLocalesImport[defaultLocale](),
    ]),
    AntdLocalesImports[defaultLocale](),
  ])
    .then(([moment, antdLocale]) => {
      moment[0].locale(defaultLocale);
      pushLoadedLocale(defaultLocale, antdLocale.default);
      return loadedLocales[0];
    });

  // Загрузка языка текста
  const messages = mergeLocaleMessagesFiles([
    addName(
      { default: require(`@/locales/${defaultLocale}/common.json`) },
      'common',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/components.json`) },
      'components',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/AccountView.json`) },
      'AccountView',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/AdministrationOrganizations.json`) },
      'AdministrationOrganizations',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/AdministrationPanels.json`) },
      'AdministrationPanels',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/AdministrationPlanRates.json`) },
      'AdministrationPlanRates',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/AdministrationReportTemplates.json`) },
      'AdministrationReportTemplates',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/AdministrationUserOperations.json`) },
      'AdministrationUserOperations',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/AdministrationUsers.json`) },
      'AdministrationUsers',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/AdministrationValidation.json`) },
      'AdministrationValidation',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/Billing.json`) },
      'Billing',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/Coverage.json`) },
      'Coverage',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/ExpertSamples.json`) },
      'ExpertSamples',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/FilesExports.json`) },
      'FilesExports',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/FilesList.json`) },
      'FilesList',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/Login.json`) },
      'Login',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/MutationsBase.json`) },
      'MutationsBase',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/MutationsBaseSet.json`) },
      'MutationsBaseSet',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/Phenotypes.json`) },
      'Phenotypes',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/Projects.json`) },
      'Projects',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/SampleQuality.json`) },
      'SampleQuality',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/Samples.json`) },
      'Samples',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/SamplesTableMode.json`) },
      'SamplesTableMode',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/Search.json`) },
      'Search',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/SearchInOtherProjects.json`) },
      'SearchInOtherProjects',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/Variant.json`) },
      'Variant',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/VariantIGV.json`) },
      'VariantIGV',
    ),
    addName(
      { default: require(`@/locales/${defaultLocale}/Variants.json`) },
      'Variants',
    ),
  ]);
  vueI18n.setLocaleMessage(defaultLocale, messages);
  document.documentElement.setAttribute('lang', defaultLocale);

  return result;
})();


function setLocale<L extends Locale>(locale: L): LoadedLocale<L> {
  const result = getLoadedLocale(locale);
  if (!result) {
    throw new Error(`Invalid locale: "${locale}"`);
  }
  document.documentElement.setAttribute('lang', locale);
  vueI18n.locale = locale;
  getMoment().locale(locale);
  return result;
}

export function loadLocaleAsync(locale: Locale) {
  if (!(locale in Locale)) {
    return Promise.reject(`Invalid locale: "${locale}"`);
  }

  // If the same language
  if (vueI18n.locale === locale) {
    return Promise.resolve(setLocale(locale));
  }

  // If the language was already loaded
  if (getLoadedLocale(locale)) {
    return Promise.resolve(setLocale(locale));
  }

  // If the language hasn't been loaded yet
  return Promise.all([
    Promise.all([
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/common.json`)
        .then((file) => addName(file, 'common')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/components.json`)
        .then((file) => addName(file, 'components')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/AccountView.json`)
        .then((file) => addName(file, 'AccountView')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/AdministrationOrganizations.json`)
        .then((file) => addName(file, 'AdministrationOrganizations')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/AdministrationPanels.json`)
        .then((file) => addName(file, 'AdministrationPanels')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/AdministrationPlanRates.json`)
        .then((file) => addName(file, 'AdministrationPlanRates')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/AdministrationReportTemplates.json`)
        .then((file) => addName(file, 'AdministrationReportTemplates')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/AdministrationUserOperations.json`)
        .then((file) => addName(file, 'AdministrationUserOperations')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/AdministrationUsers.json`)
        .then((file) => addName(file, 'AdministrationUsers')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/AdministrationValidation.json`)
        .then((file) => addName(file, 'AdministrationValidation')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/Billing.json`)
        .then((file) => addName(file, 'Billing')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/Coverage.json`)
        .then((file) => addName(file, 'Coverage')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/ExpertSamples.json`)
        .then((file) => addName(file, 'ExpertSamples')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/FilesExports.json`)
        .then((file) => addName(file, 'FilesExports')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/FilesList.json`)
        .then((file) => addName(file, 'FilesList')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/Login.json`)
        .then((file) => addName(file, 'Login')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/MutationsBase.json`)
        .then((file) => addName(file, 'MutationsBase')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/MutationsBaseSet.json`)
        .then((file) => addName(file, 'MutationsBaseSet')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/Phenotypes.json`)
        .then((file) => addName(file, 'Phenotypes')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/Projects.json`)
        .then((file) => addName(file, 'Projects')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/SampleQuality.json`)
        .then((file) => addName(file, 'SampleQuality')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/Samples.json`)
        .then((file) => addName(file, 'Samples')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/SamplesTableMode.json`)
        .then((file) => addName(file, 'SamplesTableMode')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/Search.json`)
        .then((file) => addName(file, 'Search')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/SearchInOtherProjects.json`)
        .then((file) => addName(file, 'SearchInOtherProjects')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/Variant.json`)
        .then((file) => addName(file, 'Variant')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/VariantIGV.json`)
        .then((file) => addName(file, 'VariantIGV')),
      import(/* webpackChunkName: "locales-[request]" */ `./locales/${locale}/Variants.json`)
        .then((file) => addName(file, 'Variants')),
    ]),
    AntdLocalesImports[locale](),
    MomentLocalesImport[locale](),
  ])
    .then(([files, antdLocale]) => {
      const messages = mergeLocaleMessagesFiles(files);
      vueI18n.setLocaleMessage(locale, messages);
      pushLoadedLocale(locale, antdLocale);
      return setLocale(locale);
    });
}

// // eslint-disable-next-line @typescript-eslint/ban-ts-comment
// // @ts-expect-error
// document.loadLocaleAsync = loadLocaleAsync;
