export enum USER_ROLE {
  user = 'user',
  admin = 'admin',
  support = 'support',
  expert = 'expert',
}

// TODO: Заменить 'ascend' и 'descend' на "SortOrder"
export const enum SortOrder {
  ascend = 'ascend',
  descend = 'descend',
}

export const DATE_FRONTEND_FORMAT = 'DD.MM.YYYY';
export const DATE_BACKEND_FORMAT = 'YYYY-MM-DD';


export enum MUTATION_TYPE {
  germ = 'germ',
  somat = 'somat',
}

export enum ANALYSIS_METHOD {
  zond = 'zond',
  ampl = 'ampl',
}

export enum PATHOGEN_CLASS {
  NOCLASS = 'NOCLASS',
  CLASS1_BENIGN = 'CLASS1_BENIGN',
  CLASS2_LIKELYBENING = 'CLASS2_LIKELYBENING',
  CLASS3_VUS = 'CLASS3_VUS',
  CLASS4_LIKELYPATHOGINIC = 'CLASS4_LIKELYPATHOGINIC',
  CLASS5_PATHOGENIC = 'CLASS5_PATHOGENIC',
}


export const CLINICAL_RELEVANCE = Object.freeze({
  1: 1,
  2: 2,
  3: 3,
  4: 4,
});
export type CLINICAL_RELEVANCE = keyof typeof CLINICAL_RELEVANCE;


export const computed$ = Symbol('computed$');

export const DASH = '—';
export const RUBLE = '₽';

export enum BioDataValidationPipelineSampleType {
  genom = 'genom',
  exom = 'exom',
  unknown = 'unknown',
}

export enum SAMPLE_TASK_STATUS {
  created = 'created',
  awaiting_running = 'awaiting_running',
  await_vm_running = 'await_vm_running',
  running = 'running',
  error = 'error',
  done = 'done',
}

// export enum SampleProgressStatus {
//   in_progress = 'in_progress',
//   in_error = 'in_error',
//   opened = 'opened',
//   vus = 'vus',
//   closed = 'closed',
// }

export enum SampleStatus {
  positive = 'positive',
  negative = 'negative',
  vus = 'vus',
  open = 'open',
}

export enum PanelPreprocessingType {
  umi = 'umi',
}

export const clinicalRelevancesShortLabels = {
  [CLINICAL_RELEVANCE['1']]: 'I',
  [CLINICAL_RELEVANCE['2']]: 'II',
  [CLINICAL_RELEVANCE['3']]: 'III',
  [CLINICAL_RELEVANCE['4']]: 'IV',
};

export enum FILE_EXPORT_STATUS {
  created = 'created',
  running = 'running',
  done = 'done',
  error = 'error',
}
