import {
  shallowRef,
  computed,
} from 'vue';
import { defineStore } from 'pinia';

import { useUploadManagerStore } from '@/store/uploadManager';
import { useProjectsStore } from '@/store/projects';
import { useAdministrationStore } from '@/store/administration';
import { useBillingStore } from '@/store/billing';
import { useMutationsBasesStore } from '@/store/mutationsBases';
import { router } from '@/router';
import { $http } from '@/plugins/axios';
import { eobject } from '@/utils/empties';
import { USER_ROLE } from '@/constants';
import { vueI18n } from '@/i18n';


const accessTokenLocalStorageKey = 'accessToken';
const userIdLocalStorageKey = 'userId';
const roleLocalStorageKey = 'role';
const emailLocalStorageKey = 'email';
const user_uuidLocalStorageKey = 'user_uuid';

const backupPrefix = 'backup_';
const backup_accessTokenLocalStorageKey = `${backupPrefix}accessToken`;
const backup_userIdLocalStorageKey = `${backupPrefix}userId`;
const backup_roleLocalStorageKey = `${backupPrefix}role`;
const backup_emailLocalStorageKey = `${backupPrefix}email`;
const backup_user_uuidLocalStorageKey = `${backupPrefix}user_uuid`;


export const useAuthStore = defineStore('auth', () => {

  const accessToken = shallowRef<string>();
  const userId = shallowRef<number>();
  const role = shallowRef<USER_ROLE>();
  const email = shallowRef<string>();
  // TODO: Исправить название на camelCase
  const user_uuid = shallowRef<string>();

  const backup_accessToken = shallowRef<string>();
  const backup_userId = shallowRef<number>();
  const backup_role = shallowRef<USER_ROLE>();
  const backup_email = shallowRef<string>();
  const backup_user_uuid = shallowRef<string>();

  const hints = shallowRef(eobject);
  const settings = shallowRef(eobject);

  const logined = computed(() => Boolean((
    accessToken.value &&
      userId.value &&
      role.value &&
      user_uuid.value &&
      user_uuid.value
  )));
  const loginedAs = computed(() => Boolean((
    backup_accessToken.value &&
      backup_userId.value &&
      backup_role.value &&
      backup_email.value &&
      backup_user_uuid.value
  )));


  function logIn(
    _accessToken: string,
    _userId: number,
    _role: USER_ROLE,
    _email: string,
    _user_uuid: string,
  ) {
    accessToken.value = _accessToken;
    userId.value = _userId;
    role.value = _role;
    email.value = _email;
    user_uuid.value = _user_uuid;
    localStorage.setItem(accessTokenLocalStorageKey, _accessToken);
    localStorage.setItem(userIdLocalStorageKey, String(_userId));
    localStorage.setItem(roleLocalStorageKey, _role);
    localStorage.setItem(emailLocalStorageKey, _email);
    localStorage.setItem(user_uuidLocalStorageKey, _user_uuid);

    // Выставить авторизацию, получить баланс и словари переводов
    $http.defaults.headers.common['Authorization'] = `Bearer ${_accessToken}`;

    // Из-за взаимной зависимости двух модулей, использование нужно отложить
    setTimeout(useBillingStore().getBalance);

    $http.get('setups/hints-dict/')
      .then((response) => {
        hints.value = response.data;
      })
      .catch((error) => {
        if ($http.isCancel(error)) {
          return;
        }
        $http.showErrorMessage(error, vueI18n.t('common.stores.auth.getHintsDictError'));
      });

    $http.get('setups/settings/')
      .then((response) => {
        settings.value = response.data;
      })
      .catch((error) => {
        if ($http.isCancel(error)) {
          return;
        }
        $http.showErrorMessage(error, vueI18n.t('common.stores.auth.getSettingsError'));
      });
  }

  function logOutStores() {
    useUploadManagerStore().onLogOut();
    useProjectsStore().onLogOut();
    useAdministrationStore().onLogOut();
    useBillingStore().onLogOut();
    useMutationsBasesStore().onLogOut();
  }
  function logOut() {
    if (loginedAs.value) {
      logOutAs();
      return;
    }
    accessToken.value = undefined;
    userId.value = undefined;
    role.value = undefined;
    email.value = undefined;
    user_uuid.value = undefined;

    localStorage.removeItem(accessTokenLocalStorageKey);
    localStorage.removeItem(userIdLocalStorageKey);
    localStorage.removeItem(roleLocalStorageKey);
    localStorage.removeItem(emailLocalStorageKey);
    localStorage.removeItem(user_uuidLocalStorageKey);

    delete $http.defaults.headers.common['Authorization'];

    logOutStores();
    router.push('/');
  }

  function setBackup(
    _accessToken: string,
    _userId: number,
    _role: USER_ROLE,
    _email: string,
    _user_uuid: string,
  ) {
    localStorage.setItem(backup_accessTokenLocalStorageKey, _accessToken);
    localStorage.setItem(backup_userIdLocalStorageKey, String(_userId || ''));
    localStorage.setItem(backup_roleLocalStorageKey, _role);
    localStorage.setItem(backup_emailLocalStorageKey, _email);
    localStorage.setItem(backup_user_uuidLocalStorageKey, _user_uuid);
    backup_accessToken.value = _accessToken;
    backup_userId.value = _userId;
    backup_role.value = _role;
    backup_email.value = _email;
    backup_user_uuid.value = _user_uuid;
  }

  function logInAs(
    _accessToken: string,
    _userId: number,
    _role: USER_ROLE,
    _email: string,
    _user_uuid: string,
  ) {
    if (!logined.value) {
      return;
    }

    logOutStores();

    setBackup(
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      accessToken.value!,
      userId.value!,
      role.value!,
      email.value!,
      user_uuid.value!,
      /* eslint-enable @typescript-eslint/no-non-null-assertion */
    );
    logIn(
      _accessToken,
      _userId,
      _role,
      _email,
      _user_uuid,
    );

    // Сохранить все "sessionStorage"-данные с "backup"-префиксом
    for (let index = 0; index < sessionStorage.length; index++) {
      const key = sessionStorage.key(index);
      if (key !== null && !key.startsWith(backupPrefix)) {
        const data = sessionStorage.getItem(key);
        sessionStorage.setItem(`${backupPrefix}${key}`, String(data));
        sessionStorage.removeItem(key);
      }
    }
  }

  function logOutAs() {
    if (!loginedAs.value) {
      return;
    }

    logOutStores();

    logIn(
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      backup_accessToken.value!,
      backup_userId.value!,
      backup_role.value!,
      backup_email.value!,
      backup_user_uuid.value!,
      /* eslint-enable @typescript-eslint/no-non-null-assertion */
    );
    setBackup(
      '',
      0,
      ('') as USER_ROLE,
      '',
      '',
    );

    // Достать все "sessionStorage"-данные с "backup"-префиксом
    for (let index = 0; index < sessionStorage.length; index++) {
      const key = sessionStorage.key(index);
      if (key !== null && key.startsWith(backupPrefix)) {
        const data = sessionStorage.getItem(key);
        sessionStorage.setItem(key.slice(backupPrefix.length), String(data));
        sessionStorage.removeItem(key);
      }
    }
  }


  // Проверка наличия всех данных
  {
    const _accessToken = localStorage.getItem(accessTokenLocalStorageKey);
    const _userId = Number(localStorage.getItem(userIdLocalStorageKey)) || null;
    // TODO: сделать проверку на то, что такая роль существует, при её чтении из "localStorage"
    const _role = localStorage.getItem(roleLocalStorageKey) as USER_ROLE;
    const _email = localStorage.getItem(emailLocalStorageKey);
    const _user_uuid = localStorage.getItem(user_uuidLocalStorageKey);

    const _backup_accessToken = localStorage.getItem(backup_accessTokenLocalStorageKey);
    const _backup_userId = Number(localStorage.getItem(backup_userIdLocalStorageKey)) || null;
    const _backup_role = localStorage.getItem(backup_roleLocalStorageKey) as USER_ROLE;
    const _backup_email = localStorage.getItem(backup_emailLocalStorageKey);
    const _backup_user_uuid = localStorage.getItem(backup_user_uuidLocalStorageKey);

    if (
      _accessToken &&
      _userId &&
      _role &&
      _email &&
      _user_uuid
    ) {
      logIn(
        _accessToken,
        _userId,
        _role,
        _email,
        _user_uuid,
      );

      if (
        _backup_accessToken &&
        _backup_userId &&
        _backup_role &&
        _backup_email &&
        _backup_user_uuid
      ) {
        logInAs(
          _backup_accessToken,
          _backup_userId,
          _backup_role,
          _backup_email,
          _backup_user_uuid,
        );
      }
    }
  }

  return {
    accessToken,
    userId,
    role,
    email,
    user_uuid,

    backup_accessToken,
    backup_userId,
    backup_role,
    backup_email,
    backup_user_uuid,

    logined,
    loginedAs,

    hints,
    settings,

    logIn,
    logOut,
    logInAs,
    logOutAs,
  };
});
