import { shallowRef } from 'vue';
import { defineStore } from 'pinia';
import { message } from 'ant-design-vue';

import {
  router,
  RouteName,
} from '@/router';
import { $http } from '@/plugins/axios';
import type { MutationsBase } from '@/commonTypes';
import { vueI18n } from '@/i18n';


export const useMutationsBasesStore = defineStore('mutationsBases', () => {

  const selectedMutationsBase = shallowRef<MutationsBase>();

  function getSelectedMutationsBase(mutationsBaseId: MutationsBase['id']) {
    selectedMutationsBase.value = undefined;

    return $http.get(`projects/categories/${mutationsBaseId}/`)
      .then((response) => {
        selectedMutationsBase.value = response.data;
      })
      .catch((error) => {
        if ($http.isCancel(error)) {
          return;
        }

        if (error?.response?.status === 404) {
          message.error(
            vueI18n.t(
              'common.stores.mutationsBases.getSelectedMutationsBaseError404',
              { id: mutationsBaseId },
            ),
            5,
          );
          router.push({ name: RouteName.MutationsBase });
          return;
        }
        $http.showErrorMessage(
          error,
          vueI18n.t(
            'common.stores.mutationsBases.getSelectedMutationsBaseError',
            { id: mutationsBaseId },
          ),
        );
      });
  }

  function onLogOut() {
    selectedMutationsBase.value = undefined;
  }

  return {
    selectedMutationsBase,
    getSelectedMutationsBase,
    onLogOut,
  };
});
