
import {
  defineComponent,
  onUnmounted,
  shallowRef,
} from 'vue';
import {
  Menu,
  Icon,
  message,
} from 'ant-design-vue';
import { $http } from '@/plugins/axios';
import { vueI18n } from '@/i18n';


// BUG: из-за странного бага с созданием двух компонентов одновременно, пришлось вынести данные сюда
let getProgressTimeoutId = 0;
let getProgressAbortController = $http.eAbortController;
function setProgressTimeout(getProgress: () => void) {
  getProgressTimeoutId = setTimeout(getProgress, 8000);
}
function clearProgressTimeout() {
  clearTimeout(getProgressTimeoutId);
  getProgressAbortController.abort();
}

export default defineComponent({
  name: 'InProgressMenuItem',
  components: {
    'a-menu-item': Menu.Item,
    'a-icon': Icon,
  },
  setup() {
    clearProgressTimeout();
    const taskInProgress = shallowRef(0);

    function getProgress() {
      clearProgressTimeout();
      getProgressAbortController = new AbortController();

      $http.get<{task_in_progress: number}>(
        'setups/progress/',
        { signal: getProgressAbortController.signal },
      )
        .then((response) => {
          clearProgressTimeout();
          if (response.config.signal !== getProgressAbortController.signal) {
            return;
          }
          taskInProgress.value = response.data.task_in_progress;
          setProgressTimeout(getProgress);
        })
        .catch((error) => {
          clearProgressTimeout();
          if ($http.isCancel(error)) {
            return;
          }
          message.error(
            $http.parseError(vueI18n.t('common.App.InProgressMenuItem.getError'), error),
            5,
          );
          setProgressTimeout(getProgress);
        });
    }
    getProgress();

    onUnmounted(clearProgressTimeout);

    return {
      taskInProgress,
      getProgress,
    };
  },
});
