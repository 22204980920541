import { shallowRef } from 'vue';
import { defineStore } from 'pinia';

import type { User } from '@/commonTypes';


export const useAdministrationStore = defineStore('administration', () => {
  const selectedUser = shallowRef<User>();
  
  function setSelectedUser(user: typeof selectedUser.value) {
    selectedUser.value = user;
  }
  function onLogOut() {
    selectedUser.value = undefined;
  }
  
  return {
    selectedUser,
    setSelectedUser,
    onLogOut,
  };
});
