import Vue from 'vue';
import {
  Modal,
  message,
  notification,
} from 'ant-design-vue';


/**
 * Вместо полной установки через "use",
 * сделана частичная установка.
 * Выставить просто атрибуты нельзя,
 * потому что необхожимо подтянуть внутренние директивы antd
 * P.S. установка почему-то не выставляет атрибуты в компоненты
 */
Vue.use(Modal);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
Vue.use(message);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
Vue.use(notification);
Vue.prototype.$message = message;
Vue.prototype.$notification = notification;
Vue.prototype.$info = Modal.info;
Vue.prototype.$success = Modal.success;
Vue.prototype.$error = Modal.error;
Vue.prototype.$warning = Modal.warning;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$destroyAll = Modal.destroyAll;
