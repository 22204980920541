import Vue from 'vue';
import type {
  AxiosInstance,
  AxiosStatic,
  CreateAxiosDefaults,
  InternalAxiosRequestConfig,
} from 'axios';
import axios from 'axios';
import qs from 'qs';
import { message } from 'ant-design-vue';

import { deepTrimInPlace } from '@/utils/trim';
import { useAuthStore } from '@/store/auth';
import { eAbortController } from '@/utils/empties';
import { sliceWithEllipsis } from '@/utils/sliceWithEllipsis';
import {
  RouteName,
  router,
} from '@/router';
import { vueI18n } from '@/i18n';
import type { FieldsTranslation } from '@/commonTypes';


// Примеси
// Проверка на отмену запроса
function isCancel(error: unknown): boolean {
  return (
    !error ||
    (error as Error).name === 'AbortError' ||
    axios.isCancel(error)
  );
}

// Залогировать ошибку
function logError(error: unknown) {
  if (!isCancel(error)) {
    console.log(error);
  }
  return Promise.reject(error);
}

// Вернуть пустую ошибку, если запрос был отменён или это была ошибка авторизации
function nullErrorIfCancelOrUnauthorized(error: unknown) {
  // Если это отмена запроса, то ошибка пустая
  if (axios.isCancel(error)) {
    return Promise.reject();
  }

  // Если это ошибка авторизации, то ошибка пустая
  if (
    axios.isAxiosError(error) &&
    (error.response?.status === 401 || error.response?.status === 403) &&
    (
      error.config?.url !== 'users/login/' &&
      error.config?.url !== 'users/register/' &&
      error.config?.url !== 'users/reset-password/' &&
      error.config?.url !== 'users/confirm-email/'
    )
  ) {
    message.error({
      content: vueI18n.t('common.http.notAuthorized'),
      duration: 5,
      key: 'notAuthorized',
    });
    useAuthStore().logOut();
    if (router.currentRoute.name !== RouteName.Login) {
      router.push('/');
    }
    return Promise.reject();
  }

  return Promise.reject(error);
}


// TODO: вынести в отдельный файл, сверить с другим проектом
// Парсинг ошибки
function parseError(
  premessage: string | undefined,
  error: unknown,
  fieldsTranslation?: FieldsTranslation,
) {
  let message = vueI18n.t('common.http.programError');
  if (axios.isAxiosError(error)) {
    if (error.response && error.response.status < 500) {
      try {
        message = parseErrorData(error.response.data, fieldsTranslation);
      } catch (parsingError) {
        console.error('Error while parsing error from server:', parsingError);
        console.error('Invalid server response format:', error.response.data);
        message = parseServerError();
      }
    } else {
      message = parseServerError(error.response?.status);
    }
  } else {
    const errorText = String((error as Error)?.stack || error);
    // Добавить 100 символов текста ошибки
    message = `${message} "${sliceWithEllipsis(errorText, 100)}"`;
    console.error('Program error:', errorText);
  }

  return premessage ? `${premessage}: ${message}` : message;
}

interface ErrorData {
  message: string;
  code: string;
}
type MultipleErrorsData = { [errorName: string]: ErrorData[] };

function getErrorMessageFromArray(errors: ErrorData[], field?: string) {
  const result: string[] = [];
  for (const error of errors) {
    if (error && error.message) {
      result.push(error.message);
    }
  }
  return `${field}: ${result.join(', ')}`;
}

function parseErrorData(data: unknown, fieldsTranslation?: FieldsTranslation): string {
  if (data && typeof data === 'object') {
    if (Array.isArray(data)) {
      return getErrorMessageFromArray(data);
    }

    if ('detail' in data) {
      return String((data as { detail: unknown }).detail);
    }

    const result: string[] = [];
    for (const field in data) {
      const errors = (data as MultipleErrorsData)[field];
      if (!Array.isArray(errors)) {
        continue;
      }
      // TODO: сделать возможность перевода "field"
      result.push(getErrorMessageFromArray(errors, fieldsTranslation?.[field] || field));
    }
    return `\n${result.join('\n')}`;
  }

  return sliceWithEllipsis(String(data), 100);
}

function parseServerError(status?: number): string {
  const statusString = status ? (` ${status}`) : '';
  return vueI18n.t('common.http.serverError', { status: statusString });
}

// Показать ошибку
function showErrorMessage(
  error: Parameters<typeof parseError>['1'],
  premessage?: Parameters<typeof parseError>['0'],
  fieldsTranslation?: FieldsTranslation,
) {
  message.error(
    parseError(
      premessage,
      error,
      fieldsTranslation,
    ),
    5,
  );
}

// Автоматическое обрезание строки в объектах
declare module 'axios' {
  export interface AxiosRequestConfig {
    $disableTrim?: boolean,
  }
}
function autoTrimInterseption(requestConfig: InternalAxiosRequestConfig) {
  if (
    !requestConfig.$disableTrim &&
    requestConfig.data &&
    !(requestConfig.data instanceof FormData)
  ) {
    deepTrimInPlace(requestConfig.data);
  }
  return requestConfig;
}

declare module 'axios' {
  export interface AxiosRequestConfig {
    $disableRemoveEmptyUrlSearchParams?: boolean,
  }
}
function removeEmptyUrlSearchParamsInterseption(requestConfig: InternalAxiosRequestConfig) {
  if (!requestConfig.$disableRemoveEmptyUrlSearchParams && requestConfig.params) {
    for (const [name, param] of Object.entries(requestConfig.params)) {
      if (
        param !== 0 &&
        param !== false &&
        !param
      ) {
        delete requestConfig.params[name];
      }
    }
  }
  return requestConfig;
}


// Расширение "AxiosInstance"
export interface AxiosInstanceExtended extends AxiosInstance {
  isAxiosCancel: AxiosStatic['isCancel'],
  isAxiosError: AxiosStatic['isAxiosError'],
  isCancel: typeof isCancel;
  showErrorMessage: typeof showErrorMessage;
  parseError: typeof parseError;
  // "Пустой" AbortController
  eAbortController: typeof eAbortController,
  // Возможно в будущем api расширится
  // setAuthorization: (accessToken: string) => void,
  // removeAuthorization: () => void,
}

const qsOptions = { arrayFormat: 'comma' } as const;
const instanceParams: CreateAxiosDefaults = {
  baseURL: `${process.env.VUE_APP_BASE_URL}/api`,
  timeout: 60000,
  paramsSerializer(params: unknown) {
    return qs.stringify(params, qsOptions);
  },
};

function createExtendedAxiosInstance(): AxiosInstanceExtended {
  const result = axios.create(instanceParams) as AxiosInstanceExtended;

  // Для правильного срабатывания HMR в Vite
  // if (import.meta.env.DEV) {
  //   result.interceptors.request.use((request) => {
  //     const authStore = useAuthStore();
  //     if (authStore.isAuthorized && authStore.accessToken) {
  //       http.setAuthorization(authStore.accessToken);
  //       request.headers['Authorization'] = `Bearer ${authStore.accessToken}`;
  //     }
  //     return request;
  //   });
  // }

  result.interceptors.request.use(autoTrimInterseption);
  result.interceptors.request.use(removeEmptyUrlSearchParamsInterseption);
  result.interceptors.response.use(undefined, logError);
  result.interceptors.response.use(undefined, nullErrorIfCancelOrUnauthorized);

  result.isAxiosCancel = axios.isCancel;
  result.isAxiosError = axios.isAxiosError;
  result.isCancel = isCancel;
  result.parseError = parseError;
  result.showErrorMessage = showErrorMessage;
  result.eAbortController = eAbortController;

  // result.setAuthorization = (accessToken: string) => {
  //   result.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  // };

  // result.removeAuthorization = () => {
  //   delete result.defaults.headers.common['Authorization'];
  // };

  return result;
}

export const $http = createExtendedAxiosInstance();
Vue.prototype.$http = $http;

declare module 'vue/types/vue' {
  interface Vue {
    $http: AxiosInstanceExtended;
  }
}
