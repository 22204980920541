import {
  computed,
  shallowRef,
} from 'vue';
import type { ComponentPublicInstance } from 'vue';
import { defineStore } from 'pinia';
import { message } from 'ant-design-vue';
import type { ValidationRule } from 'ant-design-vue/types/form-model/form';

import {
  loadLocaleAsync,
  vueI18n,
  Locale,
  loadingDefaultLocalePromise,
  defaultLocale,
} from '@/i18n';
import type { LoadedLocale } from '@/i18n';
import {
  ANALYSIS_METHOD,
  BioDataValidationPipelineSampleType,
  CLINICAL_RELEVANCE,
  MUTATION_TYPE,
  PATHOGEN_CLASS,
  USER_ROLE,
  SAMPLE_TASK_STATUS,
} from '@/constants';
import { objectToOptions } from '@/utils/objectToOptions';
import type { Option } from '@/commonTypes';
import type { TouchedFields } from '@/plugins/antd/fieldRules';


export const useLocaleStore = defineStore('locale', () => {
  const localeLoading = shallowRef(true);
  const loadedLocale = shallowRef<LoadedLocale>();

  const selectedLocaleLocalStorageKey = 'SELECTED_LOCALE_LOCAL_STORAGE_KEY';
  async function loadLocale(locale: Locale) {
    try {
      const _loadedLocale = await loadLocaleAsync(locale);
      loadedLocale.value = _loadedLocale;
      localStorage.setItem(selectedLocaleLocalStorageKey, locale);
    } catch (error) {
      message.error('Error while loading locale');
      console.error('Error while loading locale', error);
    }
  }

  // Загрузка локали по-умолчанию и выбранной пользователем
  (async function () {
    try {
      const _loadedLocale = await loadingDefaultLocalePromise;
      loadedLocale.value = _loadedLocale;
    } catch (error) {
      message.error('Error while loading default locale');
      console.error('Error while loading default locale', error);
    }


    const selectedLocale = localStorage.getItem(selectedLocaleLocalStorageKey);
    if (
      selectedLocale &&
      selectedLocale in Locale &&
      selectedLocale !== defaultLocale
    ) {
      await loadLocale(selectedLocale as Locale);
    }

    localeLoading.value = false;
  })();

  // TODO: временная функция, изменится, если будет больше 2-ух языков
  function toggleLocale() {
    if (localeLoading.value) {
      return;
    }

    const currentLocale = loadedLocale.value?.[0] || vueI18n.fallbackLocale as Locale;
    const newLocale = currentLocale === Locale.ru ? Locale.en : Locale.ru;

    loadLocale(newLocale)
      .finally(() => {
        localeLoading.value = false;
      });
  }


  const modalOkCancelText = computed(() => ({
    okText: vueI18n.t('common.text.ModalOk') as string,
    cancelText: vueI18n.t('common.text.ModalCancel') as string,
  }));
  // TODO: "common.text" сделать отдельным объектом, а не несколько разных computed
  const errorTitle = computed(() => vueI18n.t('common.text.errorTitle'));
  const actions = computed(() => vueI18n.t('common.text.actions'));
  const yes = computed(() => vueI18n.t('common.text.yes'));
  const no = computed(() => vueI18n.t('common.text.no'));
  const noData = computed(() => vueI18n.t('common.text.noData'));
  const byDefault = computed(() => vueI18n.t('common.text.byDefault'));
  const loading = computed(() => `${vueI18n.t('common.text.loading')}...`);
  const quickSearch = computed(() => vueI18n.t('common.text.quickSearch'));
  const hg38 = computed(() => vueI18n.t('common.text.hg38'));
  const dontAsk = computed(() => vueI18n.t('common.text.dontAsk'));
  const continueQuestion = computed(() => vueI18n.t('common.text.continueQuestion'));

  const USER_ROLE_TRANSLATION = computed(() => ({
    [USER_ROLE.user]: vueI18n.t('common.constants.USER_ROLE.user'),
    [USER_ROLE.admin]: vueI18n.t('common.constants.USER_ROLE.admin'),
    [USER_ROLE.support]: vueI18n.t('common.constants.USER_ROLE.support'),
    [USER_ROLE.expert]: vueI18n.t('common.constants.USER_ROLE.expert'),
  }));
  const USER_ROLE_TRANSLATION_OPTIONS = computed(
    () => objectToOptions(USER_ROLE_TRANSLATION.value),
  );

  const MUTATION_TYPE_TRANSLATION = computed(() => ({
    [MUTATION_TYPE.germ]: vueI18n.t('common.constants.MUTATION_TYPE.germ'),
    [MUTATION_TYPE.somat]: vueI18n.t('common.constants.MUTATION_TYPE.somat'),
  }));
  const MUTATION_TYPE_TRANSLATION_OPTIONS = computed(
    () => objectToOptions(MUTATION_TYPE_TRANSLATION.value),
  );
  const MUTATION_TYPE_TRANSLATION_EXTENDED = computed(() => ({
    ...MUTATION_TYPE_TRANSLATION.value,
    [`${MUTATION_TYPE.germ}${MUTATION_TYPE.somat}`]: (
      `${MUTATION_TYPE_TRANSLATION.value.germ}, ${MUTATION_TYPE_TRANSLATION.value.somat}`
    ),
  }));

  const MUTATION_TYPE_FULLTEXT1_TRANSLATION = computed(() => ({
    [MUTATION_TYPE.germ]: vueI18n.t('common.constants.MUTATION_TYPE_FULLTEXT1.germ'),
    [MUTATION_TYPE.somat]: vueI18n.t('common.constants.MUTATION_TYPE_FULLTEXT1.somat'),
  }));
  const MUTATION_TYPE_FULLTEXT1_TRANSLATION_OPTIONS = computed(
    () => objectToOptions(MUTATION_TYPE_FULLTEXT1_TRANSLATION.value),
  );
  const MUTATION_TYPE_FULLTEXT2_TRANSLATION = computed(() => ({
    [MUTATION_TYPE.germ]: vueI18n.t('common.constants.MUTATION_TYPE_FULLTEXT2.germ'),
    [MUTATION_TYPE.somat]: vueI18n.t('common.constants.MUTATION_TYPE_FULLTEXT2.somat'),
  }));
  const MUTATION_TYPE_FULLTEXT2_TRANSLATION_OPTIONS = computed(
    () => objectToOptions(MUTATION_TYPE_FULLTEXT1_TRANSLATION.value),
  );

  const ANALYSIS_METHOD_TRANSLATION = computed(() => ({
    [ANALYSIS_METHOD.zond]: vueI18n.t('common.constants.ANALYSIS_METHOD.zond'),
    [ANALYSIS_METHOD.ampl]: vueI18n.t('common.constants.ANALYSIS_METHOD.ampl'),
  }));
  const ANALYSIS_METHOD_TRANSLATION_OPTIONS = computed(
    () => objectToOptions(ANALYSIS_METHOD_TRANSLATION.value),
  );

  /* eslint-disable max-len */
  const PATHOGEN_CLASS_TRANSLATION = computed(() => ({
    [PATHOGEN_CLASS.NOCLASS]: vueI18n.t('common.constants.PATHOGEN_CLASS.NOCLASS'),
    [PATHOGEN_CLASS.CLASS1_BENIGN]: vueI18n.t('common.constants.PATHOGEN_CLASS.CLASS1_BENIGN'),
    [PATHOGEN_CLASS.CLASS2_LIKELYBENING]: vueI18n.t('common.constants.PATHOGEN_CLASS.CLASS2_LIKELYBENING'),
    [PATHOGEN_CLASS.CLASS3_VUS]: vueI18n.t('common.constants.PATHOGEN_CLASS.CLASS3_VUS'),
    [PATHOGEN_CLASS.CLASS4_LIKELYPATHOGINIC]: vueI18n.t('common.constants.PATHOGEN_CLASS.CLASS4_LIKELYPATHOGINIC'),
    [PATHOGEN_CLASS.CLASS5_PATHOGENIC]: vueI18n.t('common.constants.PATHOGEN_CLASS.CLASS5_PATHOGENIC'),
  }));
  /* eslint-enable max-len */
  const PATHOGEN_CLASS_TRANSLATION_OPTIONS = computed(
    () => objectToOptions(PATHOGEN_CLASS_TRANSLATION.value),
  );

  /* eslint-disable max-len */
  const PATHOGEN_CLASS_SHORTTEXT_TRANSLATION = computed(() => ({
    [PATHOGEN_CLASS.NOCLASS]: vueI18n.t('common.constants.PATHOGEN_CLASS_SHORTTEXT.NOCLASS'),
    [PATHOGEN_CLASS.CLASS1_BENIGN]: vueI18n.t('common.constants.PATHOGEN_CLASS_SHORTTEXT.CLASS1_BENIGN'),
    [PATHOGEN_CLASS.CLASS2_LIKELYBENING]: vueI18n.t('common.constants.PATHOGEN_CLASS_SHORTTEXT.CLASS2_LIKELYBENING'),
    [PATHOGEN_CLASS.CLASS3_VUS]: vueI18n.t('common.constants.PATHOGEN_CLASS_SHORTTEXT.CLASS3_VUS'),
    [PATHOGEN_CLASS.CLASS4_LIKELYPATHOGINIC]: vueI18n.t('common.constants.PATHOGEN_CLASS_SHORTTEXT.CLASS4_LIKELYPATHOGINIC'),
    [PATHOGEN_CLASS.CLASS5_PATHOGENIC]: vueI18n.t('common.constants.PATHOGEN_CLASS_SHORTTEXT.CLASS5_PATHOGENIC'),
  }));
  /* eslint-enable max-len */
  const PATHOGEN_CLASS_SHORTTEXT_TRANSLATION_OPTIONS = computed(
    () => objectToOptions(PATHOGEN_CLASS_TRANSLATION.value),
  );

  const CLINICAL_RELEVANCE_TRANSLATION = computed(() => ({
    [CLINICAL_RELEVANCE[1]]: vueI18n.t('common.constants.CLINICAL_RELEVANCE.1'),
    [CLINICAL_RELEVANCE[2]]: vueI18n.t('common.constants.CLINICAL_RELEVANCE.2'),
    [CLINICAL_RELEVANCE[3]]: vueI18n.t('common.constants.CLINICAL_RELEVANCE.3'),
    [CLINICAL_RELEVANCE[4]]: vueI18n.t('common.constants.CLINICAL_RELEVANCE.4'),
  }));
  const CLINICAL_RELEVANCE_TRANSLATION_OPTIONS = computed(
    () => objectToOptions(CLINICAL_RELEVANCE_TRANSLATION.value),
  );
  const PUBLIC_OPTIONS = computed<Option<number>[]>(() => [
    {
      value: 0,
      label: vueI18n.t('common.constants.PUBLIC_OPTIONS.0'),
    },
    {
      value: 1,
      label: vueI18n.t('common.constants.PUBLIC_OPTIONS.1'),
    },
  ]);

  /* eslint-disable max-len */
  const BioDataValidationPipelineSampleTypeTranslation = computed(() => ({
    [BioDataValidationPipelineSampleType.genom]: vueI18n.t('common.constants.BioDataValidationPipelineSampleType.genom'),
    [BioDataValidationPipelineSampleType.exom]: vueI18n.t('common.constants.BioDataValidationPipelineSampleType.exom'),
    [BioDataValidationPipelineSampleType.unknown]: vueI18n.t('common.constants.BioDataValidationPipelineSampleType.unknown'),
  }));
  /* eslint-enable max-len */
  const BioDataValidationPipelineSampleTypeTranslationOptions = computed(
    () => objectToOptions(BioDataValidationPipelineSampleTypeTranslation.value),
  );

  const formRules = computed(() => {
    const required: ValidationRule = {
      required: true,
      message: vueI18n.t('common.fieldRules.required'),
    };

    const requiredInArray = Object.freeze([required]) as [ValidationRule];

    const email: ValidationRule = {
      type: 'email',
      message: vueI18n.t('common.fieldRules.email'),
    };

    const minLengthPassword: ValidationRule = {
      min: 8,
      message: vueI18n.t('common.fieldRules.minPasswordLength'),
    };


    function createMaxLength(length: number): ValidationRule {
      return {
        max: length,
        message: vueI18n.t('common.fieldRules.maxLength', { length }),
      };
    }


    function equivalentPasswords<
      FormData extends Record<string, unknown>,
      Field1 extends keyof FormData = keyof FormData,
      Field2 extends keyof FormData = keyof FormData,
    >(
      this: ComponentPublicInstance,
      formData: keyof ComponentPublicInstance,
      passwordField: Field1,
      repeatedPasswordField: Field2,
      touchedFields: TouchedFields<Field1 | Field2>,
    ): ValidationRule {
      return {
        validator: (
          rule,
          value,
          callback,
        ) => {
          if (
            touchedFields[passwordField] &&
            touchedFields[repeatedPasswordField] &&
            this[formData][passwordField] !== this[formData][repeatedPasswordField]
          ) {
            callback(vueI18n.t('common.fieldRules.equivalentPasswords'));
          } else {
            callback();
          }
        },
      };
    }


    return {
      required,
      requiredInArray,
      email,
      minLengthPassword,
      createMaxLength,
      equivalentPasswords,
    };
  });


  const SAMPLE_TASK_STATUS_TRANSLATION = computed(() => ({
    [SAMPLE_TASK_STATUS.created]: vueI18n.t('common.constants.SAMPLE_TASK_STATUS.created'),
    [SAMPLE_TASK_STATUS.awaiting_running]: vueI18n.t(
      'common.constants.SAMPLE_TASK_STATUS.awaiting_running',
    ),
    [SAMPLE_TASK_STATUS.await_vm_running]: vueI18n.t(
      'common.constants.SAMPLE_TASK_STATUS.await_vm_running',
    ),
    [SAMPLE_TASK_STATUS.running]: vueI18n.t('common.constants.SAMPLE_TASK_STATUS.running'),
    [SAMPLE_TASK_STATUS.error]: vueI18n.t('common.constants.SAMPLE_TASK_STATUS.error'),
    [SAMPLE_TASK_STATUS.done]: vueI18n.t('common.constants.SAMPLE_TASK_STATUS.done'),
  }));
  const SAMPLE_TASK_STATUS_TRANSLATION_OPTIONS = computed(
    () => objectToOptions(SAMPLE_TASK_STATUS_TRANSLATION.value),
  );


  return {
    localeLoading,
    loadedLocale,
    loadLocale,
    toggleLocale,

    modalOkCancelText,
    errorTitle,
    actions,
    yes,
    no,
    noData,
    byDefault,
    loading,
    quickSearch,
    hg38,
    dontAsk,
    continueQuestion,

    USER_ROLE_TRANSLATION,
    USER_ROLE_TRANSLATION_OPTIONS,
    MUTATION_TYPE_TRANSLATION,
    MUTATION_TYPE_TRANSLATION_OPTIONS,
    MUTATION_TYPE_TRANSLATION_EXTENDED,
    MUTATION_TYPE_FULLTEXT1_TRANSLATION,
    MUTATION_TYPE_FULLTEXT1_TRANSLATION_OPTIONS,
    MUTATION_TYPE_FULLTEXT2_TRANSLATION,
    MUTATION_TYPE_FULLTEXT2_TRANSLATION_OPTIONS,
    ANALYSIS_METHOD_TRANSLATION,
    ANALYSIS_METHOD_TRANSLATION_OPTIONS,
    PATHOGEN_CLASS_TRANSLATION,
    PATHOGEN_CLASS_TRANSLATION_OPTIONS,
    PATHOGEN_CLASS_SHORTTEXT_TRANSLATION,
    PATHOGEN_CLASS_SHORTTEXT_TRANSLATION_OPTIONS,
    CLINICAL_RELEVANCE_TRANSLATION,
    CLINICAL_RELEVANCE_TRANSLATION_OPTIONS,
    PUBLIC_OPTIONS,
    BioDataValidationPipelineSampleTypeTranslation,
    BioDataValidationPipelineSampleTypeTranslationOptions,
    formRules,
    SAMPLE_TASK_STATUS_TRANSLATION,
    SAMPLE_TASK_STATUS_TRANSLATION_OPTIONS,
  };
});
