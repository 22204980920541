import { vueI18n } from '@/i18n';


const KB = 1024;
const MB = KB * 1024;
const GB = MB * 1024;
// Переводит байты в Кило-, Мега- или Гигабайты
export function translateBytes(bytes: number): string {
  if (bytes >= GB) {
    return `${(bytes / GB).toFixed(2)} ${vueI18n.t('common.utils.translateBytes.gigabyte')}`;
  }
  if (bytes >= MB) {
    return `${(bytes / MB).toFixed(2)} ${vueI18n.t('common.utils.translateBytes.megabyte')}`;
  }
  if (bytes >= KB) {
    return `${(bytes / KB).toFixed(2)} ${vueI18n.t('common.utils.translateBytes.kilobyte')}`;
  }
  return `${bytes} ${vueI18n.t('common.utils.translateBytes.byte')}`;
}
