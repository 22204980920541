import {
  shallowRef,
  computed,
} from 'vue';
import { defineStore } from 'pinia';

import { $http } from '@/plugins/axios';
import { useAuthStore } from '@/store/auth';
import { useLocaleStore } from '@/store/locale';
import { vueI18n } from '@/i18n';


export const useBillingStore = defineStore('billing', () => {

  const authStore = useAuthStore();
  const localeStore = useLocaleStore();

  const balance = shallowRef<number>();
  function setBalance(value: typeof balance.value) {
    balance.value = value;
  }

  const balanceText = computed(() => {
    if (!balance.value) {
      return localeStore.noData;
    }
    return `${balance.value} ₽`;
  });

  let getBalanceAbortController = $http.eAbortController;
  function getBalance() {
    getBalanceAbortController.abort();
    getBalanceAbortController = new AbortController();

    $http.get<{ balance: number }>(
      `users/users/${authStore.userId}/`,
      { signal: getBalanceAbortController.signal },
    )
      .then((response) => {
        if (response.config.signal !== getBalanceAbortController.signal) {
          throw undefined;
        }
        setBalance(response.data.balance);
      })
      .catch((error) => {
        if ($http.isCancel(error)) {
          return;
        }
        $http.showErrorMessage(error, vueI18n.t('common.stores.billing.getBalanceError'));
      });
  }

  function onLogOut() {
    balance.value = undefined;
    getBalanceAbortController.abort();
  }

  return {
    balance,
    setBalance,

    balanceText,

    getBalance,

    onLogOut,
  };
});
