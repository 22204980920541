import { returnFalse } from '@/utils/return';


/* eslint-disable-next-line @typescript-eslint/no-empty-function */
export const efunction: ((...args: any[]) => any) = Object.freeze(() => {});

export const earray = new Array<any>();
Object.freeze(earray);

export const eobject: Record<string, any> = Object.freeze({});

export const eset = new Set<any>();
eset.add = () => eset;
Object.freeze(eset);

export const emap = new Map<any, any>();
emap.set = () => emap;
Object.freeze(emap);

export const eSignal = Object.freeze({
  aborted: false,
  onabort: efunction,
  reason: undefined,
  throwIfAborted: efunction,
  addEventListener: efunction,
  removeEventListener: efunction,
  dispatchEvent: returnFalse,
  any: () => eSignal,
}) as AbortSignal;

export const eAbortController = Object.freeze({
  abort: efunction,
  signal: eSignal,
}) as AbortController;
