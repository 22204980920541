import Vue from 'vue';

import { pinia } from '@/store/pinia';
import { router } from '@/router';
import '@/plugins/antd';
import '@/plugins/axios';
import App from '@/App.vue';
import { blurButton } from '@/plugins/blurButton';
import { StaticNode } from '@/components/StaticNode';
import { vueI18n } from '@/i18n';


Vue.config.productionTip = false;

Vue.component('StaticNode', StaticNode);


new Vue({
  router,
  pinia,
  i18n: vueI18n,
  // Распространяется так, чтобы избежать дублирования через импорты
  provide: { blurButton },
  render: (h) => h(App),
})
  .$mount('#app');
