export function trimIfString<T>(value: T): T {
  return (
    typeof value === 'string' ?
      value.trim() :
      value
  ) as T;
}

export function deepTrimInPlace<T>(value: T) {
  if (value && typeof value === 'object') {
    for (const [key, _value] of Object.entries(value)) {
      const type = typeof _value;
      if (_value && type === 'object') {
        deepTrimInPlace(_value);
      } else if (type === 'string') {
        (value as Record<string, unknown>)[key] = (_value as string).trim();
      }
    }
  }
}
