import { Option } from '@/commonTypes';


export function objectToOptions<ValueType>(object: Record<string, ValueType>): Option<string>[] {
  const entries = Object.entries(object);

  const result = new Array<Option<string>>(entries.length);
  for (let index = 0; index < entries.length; index++) {
    const entry = entries[index];
    result[index] = {
      value: entry[0],
      label: String(entry[1]),
    };
  }
  return result;
}
