import type { Route } from 'vue-router';

import { RouteName } from '@/router';


const previousPageNameKey = 'previousPageName';
// export const previousPageKey = 'previousPage';

export function previousPageInSessionStorage(to: Route, from: Route) {
  if (from.name && to.name !== from.name) {
    sessionStorage.setItem(previousPageNameKey, from.name);
    // sessionStorage.setItem(
    //   previousPageKey,
    //   JSON.stringify(from),
    // );
  }
}

export function getPreviousPageName(): RouteName | undefined {
  const result = sessionStorage.getItem(previousPageNameKey);
  if (result && (result in RouteName)) {
    return result as RouteName;
  }
  return undefined;
}
