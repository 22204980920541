import { shallowRef } from 'vue';
import { defineStore } from 'pinia';
import { message } from 'ant-design-vue';
import cookie from 'js-cookie';

import {
  router,
  RouteName,
} from '@/router';
import { $http } from '@/plugins/axios';
import type {
  Project,
  Sample,
  Variant,
} from '@/commonTypes';
import { vueI18n } from '@/i18n';


const noAskAboutSampleReanalyzePriceCookieKey = 'projects:noAskAboutSampleReanalyzePrice';
const noAskAboutSampleCreatePriceCookieKey = 'projects:noAskAboutSampleCreatePrice';

export const useProjectsStore = defineStore('projects', () => {

  const noAskAboutSampleReanalyzePrice = shallowRef(
    cookie.get(noAskAboutSampleReanalyzePriceCookieKey) === 'true',
  );
  function setNoAskAboutSampleReanalyzePrice(value: typeof noAskAboutSampleReanalyzePrice.value) {
    noAskAboutSampleReanalyzePrice.value = value;
    cookie.set(noAskAboutSampleReanalyzePriceCookieKey, String(value));
  }

  const noAskAboutSampleCreatePrice = shallowRef(
    cookie.get(noAskAboutSampleCreatePriceCookieKey) === 'true',
  );
  function setNoAskAboutSampleCreatePrice(value: typeof noAskAboutSampleCreatePrice.value) {
    noAskAboutSampleCreatePrice.value = value;
    cookie.set(noAskAboutSampleCreatePriceCookieKey, String(value));
  }


  const project = shallowRef<Project>();
  const getProjectPromise = shallowRef<Promise<Project | void>>();
  function getProject(projectId: Project['id']) {
    if (!getProjectPromise.value) {
      project.value = undefined;
      getProjectPromise.value = $http.get<Project>(`projects/projects/${projectId}/`)
        .then((response) => {
          project.value = response.data;
          return project.value;
        })
        .catch((error) => {
          if ($http.isCancel(error)) {
            return;
          }

          if (error?.response?.status === 404) {
            message.error(
              vueI18n.t(
                'common.stores.projects.getProjectError404',
                { id: projectId },
              ),
              5,
            );
            router.push({ name: RouteName.Projects });
            return;
          }
          $http.showErrorMessage(
            error,
            vueI18n.t('common.stores.projects.getProjectError', { id: projectId }),
          );
        })
        .finally(() => {
          getProjectPromise.value = undefined;
        });
    }
    return getProjectPromise.value;
  }

  const sample = shallowRef<Sample>();
  const getSamplePromise = shallowRef<Promise<Sample | void>>();
  function getSample(
    projectId: Project['id'],
    sampleId: Sample['id'],
  ) {
    if (!getSamplePromise.value) {
      sample.value = undefined;
      getSamplePromise.value = getProject(projectId)
        .then(async () => {
          if (!project.value) {
            return;
          }
          try {
            const response = await $http.get(`samples/samples/${sampleId}/`);
            sample.value = response.data;
            return sample.value;
          } catch (error) {
            if ($http.isCancel(error)) {
              return;
            }

            if ($http.isAxiosError(error) && error.response?.status === 404) {
              message.error(
                vueI18n.t(
                  'common.stores.projects.getSampleError404',
                  { id: sampleId },
                ),
                5,
              );
              router.push({
                name: RouteName.Samples,
                params: { project_id: router.currentRoute.params.project_id },
              });
              return;
            }
            $http.showErrorMessage(
              error,
              vueI18n.t('common.stores.projects.getSampleError', { id: sampleId }),
            );
          }
        })
        .finally(() => {
          getSamplePromise.value = undefined;
        });
    }
    return getSamplePromise.value;
  }

  const variant = shallowRef<Variant>();
  const variantData = shallowRef<{
    results: [Variant],
    phenotypes: unknown,
    transcripts: unknown,
    project_freq: Variant['project_freq'],
  }>();
  const getVariantPromise = shallowRef<Promise<Variant | void>>();

  function getVariant({
    variant_id,
    sample_id,
    project_id,
    calc_project_freq,
    with_transcripts,
  }: {
    project_id: Project['id'],
    sample_id: Sample['id'],
    variant_id: Variant['id'],
    calc_project_freq?: 1,
    with_transcripts?: 1,
  }) {

    if (!getVariantPromise.value) {
      variant.value = undefined;
      getVariantPromise.value = getSample(project_id, sample_id)
        .then(async () => {
          if (!(project.value && sample.value)) {
            return;
          }

          try {
            const response = await $http.get<NonNullable<typeof variantData.value>>(
              'samples/variants-ch/',
              {
                params: {
                  format: 'json',
                  project_id: project.value.id,
                  sample_id: sample.value.id,
                  id: variant_id,
                  calc_project_freq,
                  with_transcripts,
                },
              },
            );

            const _variant = response.data.results[0];
            if (!_variant) {
              throw { response: { status: 404 } };
            }
            // TODO: пересмотреть хранение "project_freq"
            _variant.project_freq = response.data.project_freq;
            variant.value = _variant;
            variantData.value = response.data;

          } catch (error) {
            if ($http.isCancel(error)) {
              return;
            }

            if ($http.isAxiosError(error) && error?.response?.status === 404) {
              message.error(
                vueI18n.t(
                  'common.stores.projects.getVariantError404',
                  { id: variant_id },
                ),
                5,
              );
              router.push({
                name: RouteName.Variants,
                params: {
                  project_id: String(project_id),
                  sample_id: String(sample_id),
                },
              });
              return;
            }
            $http.showErrorMessage(
              error,
              vueI18n.t('common.stores.projects.getVariantError', { id: variant_id }),
            );
          }
        })
        .finally(() => {
          getVariantPromise.value = undefined;
        });
    }

    return getVariantPromise.value;
  }


  function onLogOut() {
    project.value = undefined;
    sample.value = undefined;
    variant.value = undefined;
    variantData.value = undefined;
  }

  return {
    noAskAboutSampleReanalyzePrice,
    setNoAskAboutSampleReanalyzePrice,

    noAskAboutSampleCreatePrice,
    setNoAskAboutSampleCreatePrice,

    project,
    getProjectPromise,
    getProject,

    sample,
    getSamplePromise,
    getSample,

    variant,
    variantData,
    getVariantPromise,
    getVariant,

    onLogOut,
  };
});
