export function returnFalse(): false {
  return false;
}

export function returnId<IdType>(object: { id: IdType }): IdType {
  return object.id;
}

export function returnName<NameType>(object: { name: NameType }): NameType {
  return object.name;
}
