// import type { ShallowRef } from 'vue';

import type {
  ExtendedFile,
  ExtendedFileCopy,
  // StubLoadingState,
} from '@/store/uploadManager';
// import { $http } from '@/plugins/axios';
// import { efunction } from '@/utils/empties';
import type { FileStorage } from '@/commonTypes';


// Дополнительные поля в файлах
// Сессия загрузки
export const $uploadSession = Symbol('$uploadSession');
// Убрать из образца таблицы "SampleSettingTable" при удалении файла из списка
export const $deselectCallbackFromSample = Symbol('$deselectCallbackFromSample');
// "id" на сервере
export const $id = Symbol('$id');
// setter "id" на сервере
export const $setId = Symbol('$setId');
export function setId(
  this: ExtendedFile | ExtendedFileCopy,
  id: (ExtendedFile | ExtendedFileCopy)[typeof $id],
) {
  this[$id] = id;
}


// export const stubLoadingState: Omit<StubLoadingState, 'loadedBytes'> = {
//   fileName: '',
//   status: { value: 'success' } as ShallowRef,
//   description: { value: 'Загрузка завершена' } as ShallowRef,
//   loadCanceler: $http.eAbortController,
//   requestWithRetryTimeoutId: 0,
//   object_key: null,
//   loadedChunks: 1,
//   totalСhunks: 1,
//   startLoad: efunction,
//   prepareToRestartLoading: efunction,
//   callbacks: {
//     success: efunction,
//     error: efunction,
//   },
// };


export const enum AddFilesType {
  FROM_DESKTOP = 'FROM_DESKTOP',
  COPY_FROM_FILE_STORAGE = 'COPY_FROM_FILE_STORAGE',
  COPY_FROM_FILE_STORAGE_WITHOUT_SAVE = 'COPY_FROM_FILE_STORAGE_WITHOUT_SAVE',
  COPY_FROM_FILE_STORAGE_LINK = 'COPY_FROM_FILE_STORAGE_LINK',
}

export type AddFilesPayload = {
  [AddFilesType.FROM_DESKTOP]: undefined,
  [AddFilesType.COPY_FROM_FILE_STORAGE]: {storageId: FileStorage['id']},
  [AddFilesType.COPY_FROM_FILE_STORAGE_WITHOUT_SAVE]: {storageId: FileStorage['id']},
  [AddFilesType.COPY_FROM_FILE_STORAGE_LINK]: {url: string},
};
