import cookie from 'js-cookie';
import round from 'lodash/round';
import type {
  AxiosResponse,
  AxiosProgressEvent,
} from 'axios';
import { defineStore } from 'pinia';
import {
  computed,
  shallowReactive,
  shallowRef,
} from 'vue';
import type {
  ComputedRef, ShallowRef,
} from 'vue';

import {
  $deselectCallbackFromSample,
  $uploadSession,
  $id,
  $setId,
  AddFilesType,
} from '@/components/UploadManagerCard/constants';
import type { AddFilesPayload } from '@/components/UploadManagerCard/constants';
import { emitter } from '@/components/UploadManagerCard/emitter';
import {
  earray,
  efunction,
} from '@/utils/empties';
import { $http } from '@/plugins/axios';
import type {
  Project,
  FileCopy,
} from '@/commonTypes';
import { deleteIfHas } from '@/utils/deleteIfHas';
import type { LoadFileWorker } from '@/store/loadFile.worker';
import { translateBytes } from '@/utils/translateBytes';
import { vueI18n } from '@/i18n';


const maxIntervalMs = 10000;

export interface ExtendedFile extends File {
  [$uploadSession]: string,
  [$id]?: string,
  [$setId]: (id: ExtendedFile[typeof $id]) => void,
  [$deselectCallbackFromSample]?: () => void,
}
export interface ExtendedFileCopy extends FileCopy {
  [$uploadSession]: string,
  [$id]?: number,
  [$setId]: (id: ExtendedFile[typeof $id]) => void,
  [$deselectCallbackFromSample]?: () => void,
}
export interface StubFile extends ExtendedFile {
  name: ExtendedFile['name'],
  size: ExtendedFile['size'],
  [$id]: ExtendedFile[typeof $id],
  [$deselectCallbackFromSample]?: ExtendedFile[typeof $deselectCallbackFromSample],
}

export enum LoadingStateStatus {
  success = 'success',
  exception = 'exception',
  normal = 'normal',
  active = 'active',
}
export interface LoadingState {
  fileName: File['name'],
  // Реактивные
  status: ShallowRef<LoadingStateStatus>,
  // TODO: возможно сделать "computed", которое зависит от текущей стадии
  // TODO: (ещё должно появится поле "стадия")
  description: ShallowRef<string>,
  loadedBytes: ShallowRef<number>,
  // Не реактивные
  loadCanceler: AbortController,
  requestWithRetryTimeoutId: number,
  object_key: null | string,
  loadedChunks: number,
  totalСhunks: number,
  // Функции
  startLoad(): void,
  prepareToRestartLoading(): void,
  callbacks: {
    success: (
      id: unknown,
      file: ExtendedFile | ExtendedFileCopy,
      fileLoadingState: LoadingState,
    ) => void,
    error: (fileLoadingState: LoadingState) => void,
  },
}
export interface LoadingFileState extends LoadingState {
  // Не реактивные
  fileId: number,
  upload_id: null | unknown,
  urlsToUpload: null | string[],
  uploaded_parts: {
    ETag: unknown,
    PartNumber: number,
  }[],
  loadedAt: {
    time: number,
    bytes: number,
  }[],
  chunkSize: number,
  currentChunkLoadedBytes: number,
  // Функции
  getLoadedTimeInterval(): null | number,
  recalculateLoadedBytes(): void,
  onUploadProgress(event: AxiosProgressEvent['loaded']): void,
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LoadingCopyState extends LoadingState {}

// export interface StubLoadingState extends LoadingState {
//   status: ShallowRef<LoadingStateStatus.success>,
//   description: ShallowRef<'Загрузка завершена'>,
//   loadedBytes: ShallowRef<number>,
//   loadCanceler: typeof $http.eAbortController,
//   requestWithRetryTimeoutId: 0,
//   object_key: null,
//   loadedChunks: number,
//   totalСhunks: number,
//   startLoad: typeof efunction,
//   prepareToRestartLoading: typeof efunction,
//   callbacks: {
//     success: typeof efunction,
//     error: typeof efunction,
//   },
// }


async function requestWithRetry<Type>(
  request: () => Promise<AxiosResponse<Type>>,
  fileLoadingState: LoadingFileState,
) {
  let error;
  for (let index = 0; index < 5; index++) {
    const start = Date.now();
    try {
      return await request();
    } catch (_error) {
      if ($http.isCancel(_error)) {
        throw _error;
      }
      error = _error;
      await new Promise((resolve) => {
        fileLoadingState.requestWithRetryTimeoutId = setTimeout(
          resolve,
          5000 - (Date.now() - start),
        );
      });
    }
  }
  throw error;
}


const MAX_LOAD_FILES_WORKERS_COUNT = 3;
const loadFileWorkerPool: LoadFileWorker[] = [];
const freeLoadFileWorker: LoadFileWorker[] = [];

function getLoadFileWorker(loadingFileState: LoadingFileState): LoadFileWorker {
  const freeWorker = freeLoadFileWorker.shift();
  if (freeWorker) {
    freeWorker.currentFileLoadingState = loadingFileState;
    return freeWorker;
  }
  if (loadFileWorkerPool.length < MAX_LOAD_FILES_WORKERS_COUNT) {
    const worker = new Worker(
      /* loadFileWorkerUrl */new URL('@/store/loadFile.worker', import.meta.url),
      { type: 'module' },
    ) as LoadFileWorker;
    loadFileWorkerPool.push(worker);
    worker.currentFileLoadingState = loadingFileState;
    return worker;
  }
  throw new Error('Maximum number of file download threads exceeded');
}


function returnLoadFileWorker(worker: LoadFileWorker) {
  worker.currentFileLoadingState = undefined;
  worker.onerror = null;
  worker.onmessage = null;
  worker.onmessageerror = null;
  freeLoadFileWorker.push(worker);
}


function createFileLoadingState(
  file: ExtendedFile,
  callbacks: LoadingFileState['callbacks'],
  fields: ComputedRef<Record<string, string>>,
): LoadingFileState {

  const result: ReturnType<typeof createFileLoadingState> = {
    fileName: file.name,
    fileId: 0,
    // TODO: проверить наличие ".value" при использовании
    loadedBytes: shallowRef(0),
    status: shallowRef(LoadingStateStatus.normal),
    description: shallowRef(vueI18n.t('common.stores.uploadManager.description.waitingLoading')),

    // Нереактивные поля
    currentChunkLoadedBytes: 0,
    loadedChunks: 0,
    loadCanceler: $http.eAbortController,
    requestWithRetryTimeoutId: 0,
    uploaded_parts: [],
    loadedAt: [],
    chunkSize: 0,
    // fileId: 0,
    upload_id: null,
    object_key: null,
    urlsToUpload: null,
    totalСhunks: -1,
    callbacks,
    // Функции для работы с данными
    getLoadedTimeInterval() {
      const loadedAt = result.loadedAt;
      if (!loadedAt.length) {
        return null;
      }
      const currentTime = Date.now();
      while (currentTime - loadedAt[0].time > maxIntervalMs) {
        loadedAt.shift();
        if (!loadedAt.length) {
          return null;
        }
      }
      return currentTime - loadedAt[0].time;
    },
    recalculateLoadedBytes() {
      result.loadedBytes.value = (
        result.loadedChunks *
        result.chunkSize +
        result.currentChunkLoadedBytes
      );
    },
    onUploadProgress(loaded) {
      result.currentChunkLoadedBytes = loaded;
      result.recalculateLoadedBytes();
      result.loadedAt.push({
        time: Date.now(),
        bytes: result.loadedBytes.value,
      });
    },
    startLoad() {
      loadFile(
        file,
        result,
        fields,
      );
    },
    prepareToRestartLoading() {
      result.status.value = LoadingStateStatus.normal;
      result.description.value = vueI18n.t(
        'common.stores.uploadManager.description.waitingLoading',
      );
      /*
        Сброс загруженных частей, так как, возможно,
        при восстановлении после ошибки,
        следующее количество байт будет меньше предыдущего (до ошибки).
        И тогда
        "loadedAt[loadedAt.length - 1].bytes - loadedAt[0].bytes"
        даст отрицательный результат
      */
      result.loadedAt = [];
    },
  };
  return result;
}


type AddFilesTypeExcludeDesktop = Exclude<AddFilesType, AddFilesType.FROM_DESKTOP>;
interface GetDownloadProgressData {
  object_key: string,
  downloaded_chunks: number | null,
  total_chunks: number | null,
  file: {
    client_size: number,
    status: 'uploading' | 'error' | 'uploaded',
  },
}

function getCopyUrlAndData<AFT extends AddFilesTypeExcludeDesktop>(
  addFilesType: AFT,
  payload: AddFilesPayload[AFT],
  file: ExtendedFileCopy,
): [string, object] {
  const fileData = {
    path: file.path,
    size: file.size,
    upload_session: file[$uploadSession],
  };

  if (addFilesType === AddFilesType.COPY_FROM_FILE_STORAGE) {
    const storageId = (payload as AddFilesPayload[AddFilesType.COPY_FROM_FILE_STORAGE]).storageId;
    return [`files/ext_storages/${storageId}/copy-file/`, fileData];
  }

  if (addFilesType === AddFilesType.COPY_FROM_FILE_STORAGE_WITHOUT_SAVE) {
    const storageId = (
      payload as AddFilesPayload[AddFilesType.COPY_FROM_FILE_STORAGE_WITHOUT_SAVE]
    ).storageId;
    return [`files/ext_storages/${storageId}/save-as-storage-link/`, fileData];
  }

  // AddFilesType.COPY_FROM_FILE_STORAGE_LINK
  return [
    'files/ext_storages/copy-file/',
    {
      file: fileData,
      storage: {
        storage_type: 'YANDEX_PUBLIC',
        settings: {
          base_path: '/',
          public_key: (payload as AddFilesPayload[AddFilesType.COPY_FROM_FILE_STORAGE_LINK]).url,
        },
      },
    },
  ];
}

function createFileCopyLoadingState<AFT extends AddFilesTypeExcludeDesktop>(
  file: ExtendedFileCopy,
  callbacks: LoadingFileState['callbacks'],
  addFilesType: AFT,
  payload: AddFilesPayload[AFT],
  fields: ComputedRef<Record<string, string>>,
): LoadingCopyState {

  const result: ReturnType<typeof createFileCopyLoadingState> = {
    fileName: file.name,

    loadedBytes: shallowRef(0),
    status: shallowRef(LoadingStateStatus.normal),
    description: shallowRef(vueI18n.t('common.stores.uploadManager.description.waitingCopying')),

    // Нереактивные поля
    loadedChunks: 0,
    loadCanceler: $http.eAbortController,
    requestWithRetryTimeoutId: 0,
    object_key: null,
    totalСhunks: 1,
    // Функции для работы с данными
    startLoad() {
      result.loadCanceler = new AbortController();
      const requestOptions = { signal: result.loadCanceler.signal };

      const urlAndData = getCopyUrlAndData(
        addFilesType,
        payload,
        file,
      );

      $http.post(
        urlAndData[0],
        urlAndData[1],
        requestOptions,
      )
        .then(async (response) => {
          result.object_key = response.data.object_key;
          const fileId = response.data.file?.id || response.data.id;
          if (addFilesType !== AddFilesType.COPY_FROM_FILE_STORAGE_WITHOUT_SAVE) {
            const getDownloadProgressData = { object_keys: [result.object_key] };
            result.status.value = LoadingStateStatus.active;
            while (result.status.value === LoadingStateStatus.active) {
              // Получить данные о загрузке
              const downloadProgressData = (await $http.post<[GetDownloadProgressData]>(
                'files/ext_storages/get-download-progress/',
                getDownloadProgressData,
                requestOptions,
              )).data[0];
              result.loadCanceler.signal.throwIfAborted();

              const serverStatus = downloadProgressData.file.status;
              if (serverStatus === 'error') {
                throw {
                  response: {
                    data: vueI18n.t('common.stores.uploadManager.description.errorCopying'),
                    status: 400,
                  },
                };
              }

              result.loadedChunks = downloadProgressData.downloaded_chunks || 0;
              result.totalСhunks = downloadProgressData.total_chunks || 1;

              if (serverStatus === 'uploaded') {
                // Переход к завершению
                break;
              }

              // Посчитать примерное количество загруженных байт для полосы прогресса и описания
              const fileSize = downloadProgressData.file.client_size || file.size;
              result.loadedBytes.value = Math.ceil(
                fileSize * result.loadedChunks / result.totalСhunks,
              );

              result.description.value = vueI18n.t(
                'common.stores.uploadManager.description.copyingInProcess',
                [translateBytes(result.loadedBytes.value), translateBytes(fileSize)],
              );

              // Подождать до следующего запроса
              await new Promise((resolve) => { setTimeout(resolve, 3000); });
              result.loadCanceler.signal.throwIfAborted();
            }
          }

          // Указание байт для красивого расчёта прогресса
          result.loadedBytes.value = file.size;
          result.status.value = LoadingStateStatus.success;
          result.description.value = vueI18n.t(
            'common.stores.uploadManager.description.copyingFinished',
          );
          result.callbacks.success(
            fileId,
            file,
            result,
          );
        })
        .catch((error) => {
          if (!$http.isCancel(error)) {
            result.status.value = LoadingStateStatus.exception;
            result.description.value = $http.parseError(
              '',
              error,
              fields.value,
            );
            result.callbacks.error(result);
          }
        });
    },
    prepareToRestartLoading() {
      result.status.value = LoadingStateStatus.normal;
      result.description.value = vueI18n.t(
        'common.stores.uploadManager.description.waitingCopying',
      );
    },
    callbacks,
  };
  return result;
}


async function loadFile(
  file: ExtendedFile,
  fileLoadingState: LoadingFileState,
  fields: ComputedRef<Record<string, string>>,
) {
  fileLoadingState.status.value = LoadingStateStatus.active;
  // Подготовка загрузки
  if (!(fileLoadingState.object_key && fileLoadingState.upload_id)) {
    try {
      fileLoadingState.loadCanceler = new AbortController();
      const response = await requestWithRetry<{
        UploadId: LoadingFileState['upload_id'],
        Key: LoadingFileState['object_key'],
        file: { id: number },
      }>(
        () => $http.post(
          'files/s3/start-multipart-upload/',
          {
            object_key: file.name,
            size: file.size,
            upload_session: file[$uploadSession],
          },
          { signal: fileLoadingState.loadCanceler.signal },
        ),
        fileLoadingState,
      );
      fileLoadingState.fileId = response.data.file.id;
      fileLoadingState.upload_id = response.data.UploadId;
      fileLoadingState.object_key = response.data.Key;

      fileLoadingState.loadCanceler.signal.throwIfAborted();
    } catch (error) {
      if (!$http.isCancel(error)) {
        fileLoadingState.status.value = LoadingStateStatus.exception;
        fileLoadingState.description.value = $http.parseError(
          vueI18n.t('common.stores.uploadManager.description.errorPreparingLoading'),
          error,
          fields.value,
        );
        fileLoadingState.callbacks.error(fileLoadingState);
      }
      return;
    }
  }

  // Начало загрузки
  if (!fileLoadingState.urlsToUpload) {
    try {
      fileLoadingState.loadCanceler.signal.throwIfAborted();

      fileLoadingState.loadCanceler = new AbortController();
      const response = await requestWithRetry<{
        urls: NonNullable<LoadingFileState['urlsToUpload']>,
        chunk_size: number,
      }>(
        () => $http.post(
          'files/s3/get-multipart-upload-urls/',
          {
            upload_id: fileLoadingState.upload_id,
            object_key: fileLoadingState.object_key,
            size: file.size,
          },
          { signal: fileLoadingState.loadCanceler.signal },
        ),
        fileLoadingState,
      );

      fileLoadingState.urlsToUpload = response.data.urls;
      fileLoadingState.totalСhunks = response.data.urls.length;
      // Если размер чанка больше размера файла,
      // то при подсчёт 100% загруженных байтов будет неправильное значение
      fileLoadingState.chunkSize = Math.min(
        response.data.chunk_size,
        file.size,
      );

      fileLoadingState.loadCanceler.signal.throwIfAborted();
    } catch (error) {
      if (!$http.isCancel(error)) {
        fileLoadingState.status.value = LoadingStateStatus.exception;
        fileLoadingState.description.value = $http.parseError(
          vueI18n.t('common.stores.uploadManager.description.errorStartingLoading'),
          error,
          fields.value,
        );
        fileLoadingState.callbacks.error(fileLoadingState);
      }
      return;
    }
  }


  // Загрузка файла в отдельном потоке
  let worker: ReturnType<typeof getLoadFileWorker>;
  try {
    worker = getLoadFileWorker(fileLoadingState);
  } catch (error) {
    fileLoadingState.status.value = LoadingStateStatus.exception;
    fileLoadingState.description.value = $http.parseError(
      vueI18n.t('common.stores.uploadManager.description.errorCreateBootloader'),
      error,
      fields.value,
    );
    fileLoadingState.callbacks.error(fileLoadingState);
    return;
  }

  fileLoadingState.loadCanceler = new AbortController();
  fileLoadingState.loadCanceler.signal.addEventListener(
    'abort',
    () => { worker.postMessage({ type: 'abort' }); },
  );

  // Прослушивание событий Worker'а
  const workerLoadingWork = new Promise<void>((resolve, reject) => {
    worker.addEventListener(
      'message',
      (event) => {
        if (event.data.type === 'loadEnd') {
          resolve();
        } else if (event.data.type === 'uploadProgress') {
          fileLoadingState.onUploadProgress(event.data.payload);
        } else if (event.data.type === 'partUploaded') {
          fileLoadingState.uploaded_parts.push(event.data.payload);
        } else if (event.data.type === 'loadError') {
          reject();
          if (!event.data.payload.isCancel) {
            fileLoadingState.status.value = LoadingStateStatus.exception;
            fileLoadingState.description.value = vueI18n.t(
              'common.stores.uploadManager.description.errorLoading',
            );
            fileLoadingState.callbacks.error(fileLoadingState);
          }
        } else if (event.data.type === 'chunkLoaded') {
          fileLoadingState.loadedChunks++;
          fileLoadingState.currentChunkLoadedBytes = 0;
          fileLoadingState.recalculateLoadedBytes();
        }
      },
    );
  });


  fileLoadingState.loadedAt.push({
    time: Date.now(),
    bytes: 0,
  });
  worker.postMessage(
    {
      type: 'load',
      payload: {
        file,
        chunkSize: fileLoadingState.chunkSize,
        loadedChunks: fileLoadingState.loadedChunks,
        urlsToUpload: fileLoadingState.urlsToUpload,
      },
    },
  );

  try {
    // Ожидание завершение работы Worker'а
    await workerLoadingWork;
  } catch (error) {
    console.error(error);
    return;
  } finally {
    returnLoadFileWorker(worker);
  }


  // Окончание загрузки
  try {
    fileLoadingState.loadCanceler.signal.throwIfAborted();

    await $http.post(
      'files/s3/complete-multipart-upload/',
      {
        upload_id: fileLoadingState.upload_id,
        object_key: fileLoadingState.object_key,
        parts: fileLoadingState.uploaded_parts,
      },
    );

    fileLoadingState.status.value = LoadingStateStatus.success;
    fileLoadingState.description.value = vueI18n.t(
      'common.stores.uploadManager.description.loadingFinished',
    );

    fileLoadingState.loadCanceler.signal.throwIfAborted();
    fileLoadingState.callbacks.success(
      fileLoadingState.fileId,
      file,
      fileLoadingState,
    );
  } catch (error) {
    if (!$http.isCancel(error)) {
      fileLoadingState.status.value = LoadingStateStatus.exception;
      fileLoadingState.description.value = $http.parseError(
        vueI18n.t('common.stores.uploadManager.description.errorFinishingLoading'),
        error,
        fields.value,
      );
      fileLoadingState.callbacks.error(fileLoadingState);
    }
    return;
  }
}


export const useUploadManagerStore = defineStore('uploadManager', () => {

  const uploadManagerModalVisible = shallowRef(false);
  const isFileUploading = shallowRef(false);

  // Проект, в который загружаются файлы
  const projectToUploadFiles = shallowRef<Project['id']>();
  function uploadFiles() {
    projectToUploadFiles.value = undefined;
    uploadManagerModalVisible.value = true;
  }
  function uploadFilesInProject(projectId: Project['id']) {
    projectToUploadFiles.value = projectId;
    uploadManagerModalVisible.value = true;
  }

  // Список проектов в которые можно загружать образцы
  const projects = shallowRef(earray as Project[]);
  const getProjectsPromise = shallowRef<Promise<Project[] | void>>();
  function getProjects() {
    if (!getProjectsPromise.value) {
      getProjectsPromise.value = $http.get<{ results: Project[] }>(
        'projects/projects/?page_size=1000',
      )
        .then((response) => {
          projects.value = response.data.results;
          return projects.value;
        })
        .catch((error) => {
          $http.showErrorMessage(error, vueI18n.t('common.stores.uploadManager.getProjectsError'));
        })
        .finally(() => {
          getProjectsPromise.value = undefined;
        });
    }

    return getProjectsPromise.value;
  }


  // Загружаемые файлы
  // TODO: Исправить реактивность переменных после перехода на Vue3
  // TODO: после загрузки файлы больше не нужны и нет смысла держать из в оперативной памяти
  // Список всех файлов
  const fileList = shallowReactive(new Array<ExtendedFile | ExtendedFileCopy>());
  // Состояния загрузки файлов по их именам
  const filesLoadingState = /* shallowReactive */new Map<ExtendedFile['name'], LoadingState>();
  // Индекс файлов по их именам
  const fileListIndexByNames = /* shallowReactive */new Map<
    ExtendedFile['name'],
    ExtendedFile | ExtendedFileCopy
  >();
  // Файлы по состояниям в списке
  /*
    TODO: сделать нереактивными некоторые поля,
    потому что за их состоянием не надо следить.
    Например, "waitingFilesQueue"
  */
  const waitingFilesQueue = new Array<ExtendedFile['name']>();
  const notSelectedFiles = /* shallowReactive */new Set<ExtendedFile['name']>();
  // TODO: Кажется, можно заменить на счётчик
  const errorFiles = /* shallowReactive */new Set<ExtendedFile['name']>();
  // TODO: удалить после перехода на Vue3
  const errorFilesSize = shallowRef(0);
  const successFiles = /* shallowReactive */new Set<ExtendedFile['name']>();

  function addNotSelectedFiles(filename: ExtendedFile['name']) {
    notSelectedFiles.add(filename);
    emitter.emit('updated:notSelectedFiles');
  }
  function deleteNotSelectedFiles(filename: ExtendedFile['name']) {
    if (notSelectedFiles.delete(filename)) {
      emitter.emit('updated:notSelectedFiles');
    }
  }

  function addErrorFile(filename: ExtendedFile['name']) {
    errorFiles.add(filename);
    errorFilesSize.value++;
  }
  function deleteErrorFile(filename: ExtendedFile['name']) {
    errorFiles.delete(filename);
    errorFilesSize.value--;
  }

  function addSuccessFiles(filename: ExtendedFile['name']) {
    successFiles.add(filename);
    emitter.emit('add:successFiles');
  }


  const loadCallbacks: LoadingFileState['callbacks'] = {
    success: efunction,
    error: efunction,
  };

  function addFiles(files: ExtendedFile[]) {
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      fileList.push(file);
      filesLoadingState.set(
        file.name,
        createFileLoadingState(
          file,
          loadCallbacks,
          fields,
        ),
      );
      notSelectedFiles.add(file.name);
      fileListIndexByNames.set(file.name, file);
      waitingFilesQueue.push(file.name);
    }
    // Вызывать после всех добавлений, чтобы много раз не "$emit",
    // так как используется лишь для перерисовки
    emitter.emit('updated:notSelectedFiles');
  }

  function addFilesCopy<AFT extends AddFilesTypeExcludeDesktop>(
    files: ExtendedFileCopy[],
    addFilesType: AFT,
    payload: AddFilesPayload[AFT],
  ) {
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      fileList.push(file);
      filesLoadingState.set(
        file.name,
        createFileCopyLoadingState(
          file,
          loadCallbacks/* copyCallbacks */,
          addFilesType,
          payload,
          fields,
        ),
      );
      notSelectedFiles.add(file.name);
      fileListIndexByNames.set(file.name, file);
      waitingFilesQueue.push(file.name);
    }
    // Вызывать после всех добавлений, чтобы много раз не "$emit",
    // так как используется лишь для перерисовки
    emitter.emit('updated:notSelectedFiles');
  }

  function removeFile(index: number) {
    const file = fileList[index];
    fileList.splice(index, 1);

    file[$deselectCallbackFromSample]?.();
    filesLoadingState.delete(file.name);
    notSelectedFiles.delete(file.name);
    emitter.emit('updated:notSelectedFiles');
    fileListIndexByNames.delete(file.name);
    // TODO: возможно, следует проверять "status"
    deleteIfHas(waitingFilesQueue, file.name);
    errorFiles.delete(file.name);
    errorFilesSize.value = errorFiles.size;
    successFiles.delete(file.name);
  }

  function clearFileList() {
    for (let index = 0; index < fileList.length; index++) {
      const file = fileList[index];
      file[$deselectCallbackFromSample]?.();
    }
    fileList.splice(0, fileList.length);
    // fileList.length = 0;

    // Загрузку отменяет UploadManagerCard/index.vue
    filesLoadingState.clear();
    notSelectedFiles.clear();
    emitter.emit('updated:notSelectedFiles');

    fileListIndexByNames.clear();
    waitingFilesQueue.length = 0;
    errorFiles.clear();
    errorFilesSize.value = 0;
    successFiles.clear();

    // Загрузку отменяет UploadManagerCard/SampleFiles.vue
    loadingPrices.clear();
    totalPriceLoading.value = false;
    totalPrice.value = 0;
    totalPrice.discount = undefined;
  }


  // Для расчётов цен
  const loadingPrices = /* shallowReactive */new Set<Vue>();
  // TODO: удалить после перехода на Vue3
  const totalPriceLoading = shallowRef(false);
  const totalPrice = shallowReactive<{
    value: number,
    // or string
    discount?: number,
  }>({
    value: 0,
    discount: undefined,
  });

  function addLoadingPrice(vueComponent: Vue) {
    loadingPrices.add(vueComponent);
    totalPriceLoading.value = true;
  }
  function deleteLoadingPrices(vueComponent: Vue) {
    loadingPrices.delete(vueComponent);
    totalPriceLoading.value = loadingPrices.size > 0;
  }
  function increaseTotalPriceValue(value: number) {
    totalPrice.value = round(totalPrice.value + value, 2);
  }
  function decreaseTotalPriceValue(value: number) {
    totalPrice.value -= value;
    if (totalPrice.value > 0) {
      totalPrice.value = round(totalPrice.value, 2);
    } else {
      // Чтобы не уходить в минус при погрешностях
      totalPrice.value = 0;
    }
  }


  const noAskAboutPrice = shallowRef(cookie.get('UploadManager:noAskAboutPrice') === 'true');
  function setNoAskAboutPrice(value: typeof noAskAboutPrice.value) {
    noAskAboutPrice.value = value;
    cookie.set('UploadManager:noAskAboutPrice', String(value));
  }


  const fields = computed(() => ({
    path: vueI18n.t('common.stores.uploadManager.fields.path'),
    object_key: vueI18n.t('common.stores.uploadManager.fields.object_key'),
    size: vueI18n.t('common.stores.uploadManager.fields.size'),
    upload_session: vueI18n.t('common.stores.uploadManager.fields.upload_session'),
    parts: vueI18n.t('common.stores.uploadManager.fields.parts'),
  }));


  function onLogOut() {
    clearFileList();
    uploadManagerModalVisible.value = false;
    isFileUploading.value = false;
    projectToUploadFiles.value = undefined;
    loadCallbacks.error = efunction;
    loadCallbacks.success = efunction;
    projects.value = earray as Project[];
    getProjectsPromise.value = undefined;
    for (const worker of loadFileWorkerPool) {
      worker.terminate();
    }
    loadFileWorkerPool.length = 0;
    freeLoadFileWorker.length = 0;
  }

  return {
    MAX_LOAD_FILES_WORKERS_COUNT,

    uploadManagerModalVisible,
    isFileUploading,

    projectToUploadFiles,
    uploadFiles,
    uploadFilesInProject,

    loadCallbacks,

    projects,
    getProjectsPromise,
    getProjects,

    fileList,
    waitingFilesQueue,
    filesLoadingState,
    fileListIndexByNames,
    // errorFiles,
    errorFilesSize,
    successFiles,
    notSelectedFiles,

    addNotSelectedFiles,
    deleteNotSelectedFiles,
    addErrorFile,
    deleteErrorFile,
    addSuccessFiles,

    addFiles,
    addFilesCopy,
    removeFile,
    clearFileList,

    noAskAboutPrice,
    setNoAskAboutPrice,

    // loadingPrices,
    totalPriceLoading,
    totalPrice,

    addLoadingPrice,
    deleteLoadingPrices,
    increaseTotalPriceValue,
    decreaseTotalPriceValue,

    onLogOut,
  };
});
