import Vue from 'vue';
import VueRouter from 'vue-router';
import type { RouteConfig } from 'vue-router';
import { message } from 'ant-design-vue';

import { useAuthStore } from '@/store/auth';
import { USER_ROLE } from '@/constants';
import { previousPageInSessionStorage } from '@/router/previousPageInSessionStorage';
import { pinia } from '@/store/pinia';
import { earray } from '@/utils/empties';
import { vueI18n } from '@/i18n';


Vue.use(VueRouter);

const allRoles = Object.values(USER_ROLE);
const adminSupport = [USER_ROLE.admin, USER_ROLE.support];
const adminExpert = [USER_ROLE.admin, USER_ROLE.expert];


export enum RouteName {
  // 'Логин' = 'Логин',
  Login = 'Login',
  // 'Проекты' = 'Проекты',
  Projects = 'Projects',
  // 'Образцы' = 'Образцы',
  Samples = 'Samples',
  // 'Табличный вид образцов' = 'Табличный вид образцов',
  SamplesTableMode = 'SamplesTableMode',
  // 'Качество образца' = 'Качество образца',
  SampleQuality = 'SampleQuality',
  // 'Покрытие' = 'Покрытие',
  Coverage = 'Coverage',
  // 'Схема покрытия' = 'Схема покрытия',
  CoverageSchema = 'CoverageSchema',
  // 'Таблица покрытия' = 'Таблица покрытия',
  CoverageTable = 'CoverageTable',
  // 'Гистограмма покрытия' = 'Гистограмма покрытия',
  CoverageBarchart = 'CoverageBarchart',
  // 'Лог образца' = 'Лог образца',
  CoverageSampleLog = 'CoverageSampleLog',
  // 'Варианты' = 'Варианты',
  Variants = 'Variants',
  // 'Вариант' = 'Вариант',
  Variant = 'Variant',
  // 'Поиск в других проектах' = 'Поиск в других проектах',
  SearchInOtherProjects = 'SearchInOtherProjects',
  // 'Вариант IGV' = 'Вариант IGV',
  VariantIGV = 'VariantIGV',
  // 'База мутаций' = 'База мутаций',
  MutationsBase = 'MutationsBase',
  // 'База мутаций, набор' = 'База мутаций, набор',
  MutationsBaseSet = 'MutationsBaseSet',
  // 'Фенотипы' = 'Фенотипы',
  Phenotypes = 'Phenotypes',

  Files = 'Files',
  // 'Файлы' = 'Файлы',
  FilesList = 'FilesList',
  // 'Экспорт' = 'Экспорт',
  FilesExports = 'FilesExports',

  Administration = 'Administration',
  // 'Администрирование пользователей' = 'Администрирование пользователей',
  AdministrationUsers = 'AdministrationUsers',
  // 'Операции пользователя' = 'Операции пользователя',
  AdministrationUserOperations = 'AdministrationUserOperations',
  // 'Организации' = 'Организации',
  AdministrationOrganizations = 'AdministrationOrganizations',
  // 'Администрирование панелей' = 'Администрирование панелей',
  AdministrationPanels = 'AdministrationPanels',
  // 'Шаблоны отчётов' = 'Шаблоны отчётов',
  AdministrationReportTemplates = 'AdministrationReportTemplates',
  // 'Тарифные планы' = 'Тарифные планы',
  AdministrationPlanRates = 'AdministrationPlanRates',
  // 'Валидация' = 'Валидация',
  AdministrationValidation = 'AdministrationValidation',
  // 'Баланс' = 'Баланс',
  Billing = 'Billing',
  // 'Аккаунт' = 'Аккаунт',
  Account = 'Account',
  // 'Поиск' = 'Поиск',
  Search = 'Search',
  // 'Образцы эксперта' = 'Образцы эксперта',
  ExpertSamples = 'ExpertSamples',
}


declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth: boolean,
    rolesHaveAccess: USER_ROLE[],
  }
}

interface SimpleRouteConfig {
  path: RouteConfig['path'],
  name: RouteName,
  meta: NonNullable<RouteConfig['meta']>,
  alias?: RouteConfig['alias'],
  children?: SimpleRouteConfig[],
  // Для путей с компонентом по умолчанию (возникает предупреждение в консоли)
  leaveUntitled?: boolean,
}

function createLazyRoute(path: SimpleRouteConfig) {
  const result: RouteConfig = {
    ...path,
    component: () => import(`@/views/${path.name}/index.vue`),
  };
  if (path.children) {
    result.children = path.children.map(createLazyRoute);
  }
  if (path.leaveUntitled) {
    delete result.name;
  }
  return result;
}


const routes: SimpleRouteConfig[] = [
  {
    path: '/',
    name: RouteName.Login,
    meta: {
      requiresAuth: false,
      rolesHaveAccess: earray,
    },
  },
  {
    path: '/projects',
    name: RouteName.Projects,
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
  },
  {
    path: '/projects/:project_id/samples',
    name: RouteName.Samples,
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
  },
  {
    path: '/projects/:project_id/samples/table-mode',
    name: RouteName.SamplesTableMode,
    meta: {
      requiresAuth: true,
      rolesHaveAccess: adminExpert,
    },
  },
  {
    path: '/projects/:project_id/samples/:sample_id/quality',
    name: RouteName.SampleQuality,
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
  },
  {
    path: '/projects/:project_id/samples/:sample_id/coverage',
    name: RouteName.Coverage,
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
    children: [
      {
        path: '/projects/:project_id/samples/:sample_id/coverage/schema',
        name: RouteName.CoverageSchema,
        meta: {
          requiresAuth: true,
          rolesHaveAccess: allRoles,
        },
      },
      {
        path: '/projects/:project_id/samples/:sample_id/coverage/table',
        name: RouteName.CoverageTable,
        meta: {
          requiresAuth: true,
          rolesHaveAccess: allRoles,
        },
      },
      {
        path: '/projects/:project_id/samples/:sample_id/coverage/barchart',
        name: RouteName.CoverageBarchart,
        meta: {
          requiresAuth: true,
          rolesHaveAccess: allRoles,
        },
      },
      {
        path: '/projects/:project_id/samples/:sample_id/coverage/log',
        name: RouteName.CoverageSampleLog,
        meta: {
          requiresAuth: true,
          rolesHaveAccess: allRoles,
        },
      },
    ],
  },
  {
    path: '/projects/:project_id/samples/:sample_id/variants',
    name: RouteName.Variants,
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
  },
  {
    path: '/projects/:project_id/samples/:sample_id/variants/:variant_id',
    alias: '/projects/:project_id/samples/table-mode/:sample_id/:variant_id',
    name: RouteName.Variant,
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
  },
  {
    path: '/projects/:project_id/samples/:sample_id/variants/:variant_id/search-in-other-samples',
    name: RouteName.SearchInOtherProjects,
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
  },
  {
    path: '/projects/:project_id/samples/:sample_id/variants/:variant_id/igv',
    name: RouteName.VariantIGV,
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
  },
  {
    path: '/mutations-base',
    name: RouteName.MutationsBase,
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
  },
  {
    path: '/mutations-base/:setId',
    name: RouteName.MutationsBaseSet,
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
  },
  {
    path: '/projects/phenotypes',
    name: RouteName.Phenotypes,
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
  },
  {
    path: '/files/list',
    name: RouteName.Files,
    leaveUntitled: true,
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
    children: [
      {
        path: '',
        name: RouteName.FilesList,
        meta: {
          requiresAuth: true,
          rolesHaveAccess: allRoles,
        },
      },
      {
        path: '/files/exports',
        name: RouteName.FilesExports,
        meta: {
          requiresAuth: true,
          rolesHaveAccess: allRoles,
        },
      },
    ],
  },
  {
    path: '/administration/users',
    name: RouteName.Administration,
    leaveUntitled: true,
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
    children: [
      {
        path: '',
        name: RouteName.AdministrationUsers,
        meta: {
          requiresAuth: true,
          rolesHaveAccess: adminSupport,
        },
      },
      {
        path: '/administration/users/:userId/operations',
        name: RouteName.AdministrationUserOperations,
        meta: {
          requiresAuth: true,
          rolesHaveAccess: adminSupport,
        },
      },
      {
        path: '/administration/organizations',
        name: RouteName.AdministrationOrganizations,
        meta: {
          requiresAuth: true,
          rolesHaveAccess: adminSupport,
        },
      },
      {
        path: '/administration/panels',
        name: RouteName.AdministrationPanels,
        meta: {
          requiresAuth: true,
          rolesHaveAccess: adminSupport,
        },
      },
      {
        path: '/administration/report-templates',
        name: RouteName.AdministrationReportTemplates,
        meta: {
          requiresAuth: true,
          rolesHaveAccess: adminSupport,
        },
      },
      {
        path: '/administration/plan-rates',
        name: RouteName.AdministrationPlanRates,
        meta: {
          requiresAuth: true,
          rolesHaveAccess: adminSupport,
        },
      },
      {
        path: '/administration/validation',
        name: RouteName.AdministrationValidation,
        meta: {
          requiresAuth: true,
          rolesHaveAccess: adminSupport,
        },
      },
    ],
  },
  {
    path: '/billing',
    name: RouteName.Billing,
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
  },
  {
    path: '/account',
    name: RouteName.Account,
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
  },
  {
    path: '/search',
    name: RouteName.Search,
    meta: {
      requiresAuth: true,
      rolesHaveAccess: adminExpert,
    },
  },
  {
    path: '/samples',
    name: RouteName.ExpertSamples,
    meta: {
      requiresAuth: true,
      rolesHaveAccess: adminExpert,
    },
  },
];

const x0y0 = Object.freeze({
  x: 0,
  y: 0,
});
export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...routes.map(createLazyRoute),
    {
      path: '*',
      redirect: '/',
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.name === from.name) {
      return x0y0;
    }
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return x0y0;
  },
});
// TODO: временно, после рефакторинга убрать
export default router;

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore(pinia);

  if ((to.fullPath === from.fullPath) && from.name) {
    next(false);

  } else if (!authStore.logined && to.meta?.requiresAuth) {
    authStore.logOut();
    next({ name: RouteName.Login });

  } else if (authStore.logined && !to.meta?.requiresAuth) {
    next({ name: RouteName.Projects });

  } else if (authStore.logined && !to.meta?.rolesHaveAccess.includes(authStore.role as USER_ROLE)) {
    message.error(vueI18n.t('common.noAccessToPage'), 5);
    next({ name: RouteName.Projects });

  } else {
    next();
  }
});


router.afterEach(previousPageInSessionStorage);

// router.afterEach((to) => {
//   Vue.nextTick(() => {
//     document.title = to.meta.title || 'Анализ Геномных Данных';
//   });
// });
